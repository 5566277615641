import { combineReducers, createStore } from 'redux';

import simConfig from "./reducers/simConfig";
import variations from "./reducers/variations";
import bundledItems from "./reducers/bundledItems";
import add_to_cart_form_data from "./reducers/add_to_cart_form_data";
import validateSimConfig from "./reducers/validateSimConfig";
import validateAltNumberConfig from "./reducers/validateAltNumberConfig";

const rootReducer = combineReducers({
  simConfig,
  variations,
  bundledItems,
  add_to_cart_form_data,
  validateSimConfig,
  validateAltNumberConfig,
});

export default function configureStore(initialState) {
  return createStore(rootReducer, initialState);
}
