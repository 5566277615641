export default () => {
  $('body').on('click', '[data-default-tab]', function(e) {
    const $this = $(this);
    let $modal = null;
    const $zonesPanel = $('#zonesPanel');

    if ($this.data('toggle') === 'modal') {
      const modalSelector = $this.attr('href') || $this.data('href');
      $modal = $(modalSelector);
      $zonesPanel.appendTo($modal.find('.modal-content'));
      $modal.modal('show');
    } else if ($this.data('toggle') === 'this-modal-overlay') {
      e.preventDefault();
      $modal = $this.closest('.modal');
      if ($modal.length) {
        $modal.find('.modal-content').not('.modal-content-alt').hide();
        $modal.find('.modal-content-alt').append($zonesPanel).show();
      } else {
        $modal = $($this.data('target-modal'));
        $zonesPanel.appendTo($modal.find('.modal-content'));
        $modal.modal('show');
      }

    }

    $zonesPanel.show();
    $('a[href="' + $(e.target).data('default-tab') + '"]').tab('show');
  });

  $('body').on('click', '[data-dismiss="modal-content-alt"]', function(e) {
    e.preventDefault();
    const $this = $(this);
    const $modal = $this.closest('.modal');
    $modal.find('.modal-content').not('.modal-content-alt').show();
    $modal.find('.modal-content-alt').hide();
  });
};