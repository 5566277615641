// eslint-disable-next-line no-unused-vars

const GLOBAL_CONFIG = window.jsconfig || {};
import Vue from 'vue';

export default {
  init(rootId, productConfig) {

    const {
      variations,
      add_to_cart_form_data,
      travelsim_number,
      validateSimConfig,
      validateAltNumberConfig,
      freeShippingThreshold,
    } = productConfig;

    const {
      amount,
      basePrice,
      callCredit,
      alternativeNumber,
      dataBundle,
      dataPlan,
      voiceBundle,
      voicePlan,
    } = productConfig.simConfig;

    let locale = 'en-US';
    if ($('html').attr('lang')) {
      locale = $('html').attr('lang');
    }

    const store = {
      state: {
        amount,
        basePrice,
        callCredit,
        alternativeNumber,
        dataBundle,
        dataPlan,
        voiceBundle,
        voicePlan,
        locale,
        add_to_cart_form_data,
        travelsim_number,
        rootId,
        freeShippingThreshold,
        validateSimConfig,
        validateAltNumberConfig,
      },
      actionSetCallCredit(variationId) {
        this.state.callCredit = variationId;
      },
      actionSetDataPlan(variationId) {
        this.state.dataPlan = variationId;
      },
      actionSetDataBundle(bundleId) {
        this.state.dataBundle = bundleId;
      },
      actionSetVoicePlan(variationId) {
        this.state.voicePlan = variationId;
      },
      actionSetVoiceBundle(bundleId) {
        this.state.voiceBundle = bundleId;
      },
      actionSetAmount(amount) {
        this.state.amount = amount;
      },
    };

    const app = new Vue({ // eslint-disable-line no-unused-vars
      el: '#' + rootId,
      data: store.state,
      methods: {
        formattedPrice(cents) {
          let price = cents / 100;
          return price.toLocaleString(this.locale, {
            style: "currency",
            currency: GLOBAL_CONFIG.currency.code,
            minimumFractionDigits: 0,
            useGrouping: false,
          });
        },
        getVariationsZone(variationId) {
          if (variations[variationId] && variations[variationId].bundled_item_id) {
            return variations[variationId].bundled_item_id;
          } else {
            return 'none';
          }
        },
        handleCallCreditSelect: function (variationId) {
          store.actionSetCallCredit(variationId);
        },
        handleDataPlanSelect: function (bundleId, variationId) {
          store.actionSetDataBundle(bundleId);
          store.actionSetDataPlan(variationId);
        },
        handleVoicePlanSelect: function (bundleId, variationId) {
          store.actionSetVoiceBundle(bundleId);
          store.actionSetVoicePlan(variationId);
        },
        handleAmountSelect: function (amount) {
          store.actionSetAmount(Number(amount));
        },
        handleAddToCart: function () {
          if (!this.addToCartAllowed) {
            return;
          }
          document.getElementById('add-to-cart-' + rootId).submit();
        },
        airTimeActiveClass(variationId) {
          let classNames = [];
          if (this.callCredit.toString() === variationId.toString()) {
            classNames.push('is-active');
          }
          return classNames;
        },
        alternativeNumberActiveClass(variationId) {
          let classNames = [];
          if (this.alternativeNumber.toString() === variationId.toString()) {
            classNames.push('is-active');
          }
          return classNames;
        },
        dataActiveClass(variationId) {
          let classNames = [];
            if (this.dataPlan.toString() === variationId.toString()) {
            classNames.push('is-active');
          }
          return classNames;
        },
        dataZoneActiveClass(zoneId) {
          this.getVariationsZone(this.dataPlan);
          let classNames = [];
          if (zoneId === this.getVariationsZone(this.dataPlan)) {
            classNames.push('is-active');
          }
          return classNames;
        },

        voiceActiveClass(variationId) {
          let classNames = [];
          if (this.voicePlan.toString() === variationId.toString()) {
            classNames.push('is-active');
          }
          return classNames;
        },
        voiceZoneActiveClass(zoneId) {
          this.getVariationsZone(this.voicePlan);
          let classNames = [];
          if (zoneId === this.getVariationsZone(this.voicePlan)) {
            classNames.push('is-active');
          }
          return classNames;
        },
      },
      computed: {
        // a computed getter
        totalPrice: function () {
          let totalPrice = this.basePrice;
          if (this.callCredit && this.callCredit !== 'none') {
            totalPrice += variations[this.callCredit].price;
          }
          if (this.alternativeNumber && this.alternativeNumber !== 'none') {
            totalPrice += variations[this.alternativeNumber].price;
          }
          if (this.dataPlan && this.dataPlan !== 'none') {
            totalPrice += variations[this.dataPlan].price;
          }

          totalPrice *= this.amount;

          return totalPrice;
        },
        totalPriceFormatted: function () {
          return this.formattedPrice(this.totalPrice);
        },

        dataPlanHtml() {
          if (this.dataPlan) {
            return variations[this.dataPlan]['modal_item_html'];
          } else {
            return '';
          }
        },

        callCreditHtml() {
          if (this.callCredit) {
            return variations[this.callCredit]['modal_item_html'];
          } else {
            return '';
          }
        },

        dataPlanAddToCartData() {
          if (this.dataPlan) {
            return variations[this.dataPlan]['form_data'];
          } else {
            return [];
          }
        },

        callCreditAddToCartData() {
          if (this.callCredit) {
            return variations[this.callCredit]['form_data'];
          } else {
            return [];
          }
        },

        addToCartAllowed() {
          let allowed = true;
          if (this.validateSimConfig) {
            if (this.dataPlan === 'none' && this.callCredit === 'none') {
              allowed = false;
            }
          }
          return allowed;
        },

        freeShippingEnabled() {
          if (this.totalPrice >= this.freeShippingThreshold) {
            return true;
          } else {
            return false;
          }
        },

      },
    });
  },
};

