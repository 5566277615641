export default () => {
  jQuery(function ($) {
    showAddedToCartPopup();

    function showAddedToCartPopup() {
      const addedToCartPopup = $('.added-to-cart-popup');

      if (checkQuery() === true) {
        addedToCartPopup.addClass('show');
      }
    }

    function checkQuery() {
      const urlParams = (new URL(document.location)).searchParams;
      const modalParam = urlParams.get('modal') ? urlParams.get('modal') : '';
      const requiredParam = 'added-to-cart-modal';
      
      return requiredParam === modalParam;
    }
  });
};
