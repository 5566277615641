
function modalCreator(args) {
  const { id, title } = args;
  //let $modal = $('<div class="modal fade" tabindex="1" role="dialog" style="display: none;" aria-hidden="true">');
  const $modal = $('<div/>', {
    'id': id,
    'class': 'modal fade',
    'tabindex': '1',
    'role': 'dialog',
    'style': 'display: none;',
    'aria-hidden': 'true',
    'aria-labelledby': id,
  });

  const $modalDialog = $('<div/>', {
    'class': 'modal-dialog',
    'role': 'document',
  });

  const $modalContent = $('<div/>', {
    'class': 'modal-content',
  });

  $modal.append($modalDialog);
  $modalDialog.append($modalContent);

  $modalContent.append($('<div class="modal_close">' +
    '<button type="button" class="closeButton" data-dismiss="modal" aria-label="Close">' +
    '<svg class="closeButton_icon">' +
    '<use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#shape-close-thick"></use>' +
    '</svg>' +
    '</button>' +
    '</div>'
  ));

  $modalContent.append($('<div class="modal_largeHeader">' +
    '<div class="modal_title">' +
    title +
    '</div>' +
    '</div>'
  ));

  return $modal;
}

export default modalCreator;