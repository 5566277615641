export default () => {
  const $element = $('.js-typePlaceholder');
  const txt = $element.attr('placeholder') ? $element.attr('placeholder') : null;
  const txtLen = txt ? txt.length : null;

  let timeOut;
  let char = 0;

  $element.attr('placeholder', '|');

  function typeIt() {
    var humanize = Math.round(Math.random() * (200 - 30)) + 30;
    timeOut = setTimeout(function () {
      if(txt) {
        char++;
        var type = txt.substring(0, char);
        $element.attr('placeholder', type + '|');
        typeIt();

        if (char == txtLen) {
          $element.attr('placeholder', $element.attr('placeholder').slice(0, -1)) // remove the '|'
          clearTimeout(timeOut);
        }
      }
    }, humanize);
  }

  setTimeout(typeIt, 2000);

};
