export default () =>
{
  var Selector = {
    FIRST_STEP_FORM: '#form-sim-card-number',
    FIRST_STEP_ERROR: '.sim-card-number-error',
    THIRD_STEP_FORM: '#form-create-account',
    THIRD_STEP_ERROR: '.create-account-error',
    MY_ACCOUNT_BUTTON: '.my-account-button',
    ALL_ERRORS: '.sim-card-linker.alert-error',
    BACK_TO_BEGINNING: '.back-to-beginning',
    STEP_LOCKED: '.panelUnit2_unit-locked',
    STEP: '.panelUnit2_unit',
    PANEL_UNIT_2: '.panelUnit2',
    SPINNER_LOADER: '.spinner-loader',
  };

  var Class = {
    STEP_LOCKED: 'panelUnit2_unit-locked',
  };

  const proccessStep = (data) =>
  {
    return $.ajax({
      method: 'POST',
      url: window.ajaxurl,
      data: data,
    });
  };

  const objectToArray = (a) =>
  {
    var o = {};
    $.each(a, function () {
      if (o[this.name]) {
        if (!o[this.name].push) {
          o[this.name] = [o[this.name]];
        }
        o[this.name].push(this.value || '');
      } else {
        o[this.name] = this.value || '';
      }
    });
    return o;
  };

  $(Selector.FIRST_STEP_FORM).submit(function (e) {
    e.preventDefault();
    let data = objectToArray($(this).serializeArray());

    data.action = 'pick_sim_card';
    $(Selector.STEP).addClass(Class.STEP_LOCKED);

    let ajaxRequest = proccessStep(data);
    $(Selector.SPINNER_LOADER).show();

    ajaxRequest.done(function (response) {
      $(Selector.SPINNER_LOADER).hide();
      response = JSON.parse(response);
      if (response.status == "success") {
        if ($(Selector.THIRD_STEP_FORM).length){
          $(Selector.THIRD_STEP_FORM).parents(Selector.STEP).removeClass(Class.STEP_LOCKED);
        } else {
          $(Selector.MY_ACCOUNT_BUTTON).parents(Selector.STEP).removeClass(Class.STEP_LOCKED);
        }
        $(Selector.FIRST_STEP_ERROR).addClass('hidden');
      } else {
        $(Selector.FIRST_STEP_FORM).parents(Selector.STEP).removeClass(Class.STEP_LOCKED);
        $(Selector.FIRST_STEP_ERROR).text(response.message);
        $(Selector.FIRST_STEP_ERROR).removeClass('hidden');
      }
    });

    ajaxRequest.fail(function (response) { // eslint-disable-line no-unused-vars
      $(Selector.FIRST_STEP_FORM).parents(Selector.STEP).removeClass(Class.STEP_LOCKED);
    });
  });

  $(Selector.THIRD_STEP_FORM).submit(function (e) {
    e.preventDefault();
    let firstStepData = objectToArray($(Selector.FIRST_STEP_FORM).serializeArray());
    let data = objectToArray($(this).serializeArray());
    data = Object.assign(data, firstStepData);

    data.action = 'create_account';
    if (data.user_id) {
      data.action = 'confirm_account';
    }
    data.create_account_nonce = $('#create_account_nonce').val();
    $(Selector.STEP).addClass(Class.STEP_LOCKED);

    let ajaxRequest = proccessStep(data);

    ajaxRequest.done(function (response) {
      response = JSON.parse(response);
      if (response.status == 'success') {
        $(Selector.MY_ACCOUNT_BUTTON).parents(Selector.STEP).removeClass(Class.STEP_LOCKED);
        $(Selector.THIRD_STEP_ERROR).addClass('hidden');
      } else {
        $(Selector.THIRD_STEP_FORM).parents(Selector.STEP).removeClass(Class.STEP_LOCKED);
        $(Selector.THIRD_STEP_ERROR).html(response.message);
        $(Selector.THIRD_STEP_ERROR).removeClass('hidden');
      }
    });

    ajaxRequest.fail(function (response) { // eslint-disable-line no-unused-vars
      $(Selector.THIRD_STEP_FORM).parents(Selector.STEP).removeClass(Class.STEP_LOCKED);
    });
  });

  $(Selector.BACK_TO_BEGINNING).on('click', function (e) {
    e.preventDefault();
    let data = objectToArray($(Selector.FIRST_STEP_FORM).serializeArray());
    data.action = 'back_to_beginning';

    $(Selector.STEP).addClass(Class.STEP_LOCKED);

    let ajaxRequest = proccessStep(data);
    ajaxRequest.always(function (response) { // eslint-disable-line no-unused-vars
      $(Selector.ALL_ERRORS).addClass('hidden');
      $(Selector.FIRST_STEP_FORM).parents(Selector.STEP).removeClass(Class.STEP_LOCKED);
    });
  });

};
