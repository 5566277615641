export default () => {
  function showToast() {
    const $toast = $('.js-addedToCartToast');

    $toast.addClass('show');

    setTimeout(function () {
      $toast.removeClass('show');
    }, 5000);
  }

  $('#showToast').click(function () {
    showToast();
  });
};
