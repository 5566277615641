export default () => {
  let security = jQuery('#renderedUserCard').val()

  function loadCards(start = 0) {
    let e = jQuery('.woocommerce-MyAccount-content').find('.js-panelUnit').eq(start)
    let noUser = false
    if(!e.length){
      e = jQuery('.js-activate-page-cards-container').find('.js-panelUnit').eq(start)
      noUser = true
    }

    let dataId = e.attr('data-id')

    jQuery.ajax({
      url: window.ajaxurl,
      data: {
        action: 'rendered_user_cards',
        cardId: dataId,
        security: security,
        noUser: noUser,
      },
      success: function (r) {
        if (r.data) {
          start++

          loadCards(start)

          e.find('.loading').fadeOut()
          e.find('.panelUnit_header').remove()

          e.append(r.data)

        }
      },
    })
  }

  const $panelUnit = $('.js-panelUnit');

  if ($panelUnit.length) {
    // Load user cards via ajax
    loadCards()
  }
}
