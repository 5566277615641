export default (state = {
  amount: 1,
}, action) => {
  let newState = Object.assign({}, state);

  switch (action.type) {
    case 'UPDATE_CALL_CREDIT':
      newState.callCredit = action.data;
      return newState;
    case 'UPDATE_ALTERNATIVE_NUMBER':
      newState.alternativeNumber = action.data;
      return newState;
    case 'UPDATE_DATA_PLAN':
      newState.dataPlan = action.data;
      return newState;
    case 'UPDATE_PENDING_DATA_PLAN':
      newState.pendingDataPlan = action.data;
      return newState;
    case 'CONFIRM_PENDING_DATA_PLAN':
      newState.dataPlan = newState.pendingDataPlan;
      return newState;
    case 'UPDATE_DATA_BUNDLE':
      newState.dataBundle = action.data;
      return newState;
    case 'UPDATE_PENDING_DATA_BUNDLE':
      newState.pendingDataBundle = action.data;
      return newState;
    case 'CONFIRM_PENDING_DATA_BUNDLE':
      newState.dataBundle = newState.pendingDataBundle;
      return newState;
    case 'UPDATE_VOICE_PLAN':
      newState.voicePlan = action.data;
      return newState;
    case 'UPDATE_PENDING_VOICE_PLAN':
      newState.pendingVoicePlan = action.data;
      return newState;
    case 'CONFIRM_PENDING_VOICE_PLAN':
      newState.voicePlan = newState.pendingVoicePlan;
      return newState;
    case 'UPDATE_VOICE_BUNDLE':
      newState.voiceBundle = action.data;
      return newState;
    case 'UPDATE_PENDING_VOICE_BUNDLE':
      newState.pendingVoiceBundle = action.data;
      return newState;
    case 'CONFIRM_PENDING_VOICE_BUNDLE':
      newState.voiceBundle = newState.pendingVoiceBundle;
      return newState;
    case 'UPDATE_AMOUNT':
      newState.amount = action.data;
      return newState;
    default:
      return state;
  }
};
