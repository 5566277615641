export default () => {

  /**
   * Automatic recharge limit
   */
  const $modifyLimitsButton = jQuery('.js-modifyLimitation');
  if ($modifyLimitsButton.length) {
    $modifyLimitsButton.click(function (e) {
      e.preventDefault();

      var $i = $(this).closest('tr')
      var $dataServiceId = $i.find('.js-serviceId').attr('data-value')
      var $dataGroupId = $i.find('.js-groupId').attr('value')
      var $dataNumber = $i.find('.js-number').attr('data-value')
      var $dataTransaction = $i.find('.js-transaction').attr('data-value')
      var $dataMinimumLimit = $i.find('.js-minimumlimit').attr('data-value')
      var $dataMaximumLimit = $i.find('.js-maximumlimit').attr('data-value')
      var $dataAction = $i.find('.js-minimumlimit').attr('data-action-value')

      $('.js-modifyCardForm .js-serviceId').val($dataServiceId)
      $('.js-modifyCardForm .js-groupId').val($dataGroupId)
      $('.js-modifyCardForm .js-number').val($dataNumber)

      $dataAction = $.parseJSON($dataAction)
      $('.js-modifyCardForm .js-redirect').val($dataAction['redirect'])
      $('.js-modifyCardForm .js-nonce').val($dataAction['_wpnonce'])

      $('.js-spanNumberInModal').html($dataNumber)

      $('.js-modifyCardForm input[name="minimumlimit"]').attr({
        "value": $dataMinimumLimit,
      });

      $('.js-modifyCardForm input[name="maximumlimit"]').attr({
        "value": $dataMaximumLimit,
      });

      $('.js-modifyCardForm input[name="transaction"]').attr({
        "value": $dataTransaction,
      });
    });
  }

  /**
   * Active Data plan
   */
  const $modifyDataButton = jQuery('.js-modifyData');
  if ($modifyDataButton.length) {
    $modifyDataButton.click(function (e) {
      e.preventDefault();

      var $i = $(this).closest('tr')
      var $dataDataPackage = $i.find('.js-datapackage').attr('data-value')

      $('.js-modifyDataPackageForm table tbody').empty();

      if (!$dataDataPackage) {
        return false;
      }

      $.each($.parseJSON($dataDataPackage), function (i, item) {

        var action = '';
        if (item['action']) {
          action = '<form method="post">' +
            '<input type="hidden" name="redirect" value="' + item['action']['redirect'] + '"/>' +
            '<input type="hidden" name="number" value="' + item['number'] + '"/>' +
            '<input type="hidden" name="billing_id" value="' + item['action']['billing_id'] + '"/>' +
            '<input type="hidden" name="_wpnonce" value="' + item['action']['_wpnonce'] + '"/>' +
            '<button type="submit" name="command" class="button sideTable_close" value="' + item['action']['command'] + '" style="margin: 0 auto;float: none;text-transform: capitalize;">' + item['action']['command'] + '</button>' +
            '</form>';
        }

        $('.js-modifyDataPackageForm table tbody').append(
          '<tr>' +
          '<td>' + item['name'] + '</td>' +
          '<td>' + item['status'] + '</td>' +
          '<td>' + item['data-left'] + ' / ' + item['data-total'] + '</td>' +
          '<td>' + item['valid-until'] + '</td>' +
          '<td class="text-center">' + action + '</td>' +
          '</tr>');
      });

    });
  }
}
