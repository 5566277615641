const GLOBAL_CONFIG = window.jsconfig || {};

let locale = 'en-US';
let lang = document.documentElement.getAttribute('lang');
if ( lang ) {
  locale = lang;
}

const convertToReadablePrice = (cents) => {

  let price = cents / 100;
  return price.toLocaleString(locale, {
    style:"currency",
    currency: GLOBAL_CONFIG.currency.code,
    minimumFractionDigits: 0,
    useGrouping: false,
  });
};

export default convertToReadablePrice;
