import keySort from '../util/keySort';

export default () => {

  $('.js-destinationComplete').each(function () {


    const container = this;
    const input = $(container).find('.js-destinationComplete_input');
    const submit =  $(container).find('.js-destinationComplete_submit');
    const countries = window.listOfDestinations.sort(keySort('value'));
    const globalConfig = window.countrySearchConfig || {};
    const options = {
      lookup: countries,
      lookupFilter: function (suggestion, originalQuery, queryLowerCase) {
        return suggestion.value.toLowerCase().indexOf(queryLowerCase) === 0;
      },
      appendTo: $(container),
      showNoSuggestionNotice: true,
      noSuggestionNotice: 'No results',
      maxHeight: 200,
      triggerSelectOnValidInput: false,
      autoSelectFirst: true,
      onSelect: function (suggestion) {
        window.location.href = suggestion.url;
      },
    };

    const config = $.extend({}, options, globalConfig);

    if (!countries) {
      return;
    }

    $(input).autocomplete(config);

    $(submit).on('click', e => {
      $(this).find('.autocomplete-suggestion').first().trigger('click');
    })
  });
};
