export default {
  init(args) {
    const { context } = args;

    const $context = $(context);

    const Settings = {
      QUESTION_PREFIX: 'faq-question',
      WINDOW_LOCATION_HASH: window.location.hash,
    };

    const Selector = {
      QUESTION_TOGGLE: '[data-toggle="collapse"]',
    };

    const $elements = {
      QUESTION_TOGGLE: $context.find(Selector.QUESTION_TOGGLE),
    };

    const isAnswerReference = Settings.WINDOW_LOCATION_HASH.indexOf(Settings.QUESTION_PREFIX) !== -1;
    const $targetAnswer = $(context).find(window.location.hash);


    if (isAnswerReference && $targetAnswer.length) {
      $targetAnswer.collapse('show');
      window.setTimeout(() => {
        $(window).scrollTo($targetAnswer, {
          duration: 300,
          offset: -200,
        });
      }, 400); // wait until the answer has fully opened
    }

    $elements.QUESTION_TOGGLE.each(function() {
      const $this = $(this);
      $this.on('click', function() {
        const targetAnswerSelector = $this.attr('href');
        const $targetAnswer = $context.find(targetAnswerSelector);
        if (!$targetAnswer.length) {
          return;
        }

        const origId = $targetAnswer.attr('id');
        $targetAnswer.attr('id', '');
        window.location.hash = '#' + origId;
        $targetAnswer.attr('id', origId);
      });
    });

  },
};

