export default () => {
  $('.js-stickyPanel').each(function() {
    const $this = $(this);

    const settings = {
      parent: $this.data('sticky-container'),
      offset_top: Number($this.data('sticky-offset-top')),
      sticky_class: 'is-sticky',
      recalc_every: 5,
    };

    // media query change
    function WidthChange(mq) {
      if (mq.matches) {
        $this.stick_in_parent(settings);
      } else {
        $this.trigger('sticky_kit:detach');
      }
    }

    // media query event handler
    if (matchMedia) {
      const mq = window.matchMedia('(min-width: ' + $this.data('sticky-min-width') + 'px)');
      mq.addListener(WidthChange);
      WidthChange(mq);
    }
    
  });
};