export default () => {
  const $button = $('.js-upScrollButton');
  const $destination = $('.js-upScrollDestination');

  $(document).ready(function () {
    $button.click(function () {
      $("html").animate(
        {
          scrollTop: $destination.offset().top - 105,
        },
        800
      );
    });
  });
};
