import keySort from '../util/keySort';
const listOfCountries = window.listOfCountries.sort(keySort('value'));

export default {
  name: 'country-complete',
  props: {
    onSetResult: {
      type: Function,
      required: true,
    },
    currentCountry: {
      required: true,
    },
  },
  data() {
    return {
      searchTerm: null,
      tempValue: null,
      results: [],
      isOpen: false,
      countries: listOfCountries,
      arrowCounter: -1,
    };
  },
  methods: {
    onChange(event) {
      this.isOpen = true;
      this.tempValue = event.target.value;
      this.searchTerm = event.target.value;
      this.filterResults(this.searchTerm);
      this.arrowCounter = 0;
    },
    onFocus() {
      if (!event.target.value) {
        return;
      }
      this.isOpen = true;
      this.searchTerm = event.target.value;
      this.filterResults(this.searchTerm);
      this.arrowCounter = 0;
    },
    filterResults(searchTerm) {
      const search = searchTerm.toLowerCase();
      if (search.length > 0) {
        this.results = this.countries
          .filter(item => item.value.toLowerCase().indexOf(search) === 0)
          .map(item => {
            const index = item.value.toLowerCase().indexOf(search);
            item.substrings = [
              item.value.substring(0, index),
              item.value.substring(index, search.length),
              item.value.substring(search.length),
            ];

            return item;
          });
      } else {
        this.results = [];
      }
    },
    setResult(result) {
      this.searchTerm = null;
      this.tempValue = null;
      this.isOpen = false;
      this.onSetResult(result);
    },
    handleResultItemClick(result) {
      this.setResult(result);
    },
    adjustScroll() {
      const activeItem = this.$refs.resultsList.children[this.arrowCounter];
      const scrollContainer = this.$refs.scrollContainer;

      if (!activeItem) {
        return;
      }

      let offsetTop = activeItem.offsetTop;
      let upperBound = scrollContainer.scrollTop;
      let heightDelta = activeItem.offsetHeight;
      let lowerBound = upperBound + scrollContainer.clientHeight - heightDelta;

      if (offsetTop < upperBound) {
        scrollContainer.scrollTop = offsetTop;
      } else if (offsetTop > lowerBound) {
        scrollContainer.scrollTop = offsetTop - scrollContainer.clientHeight + heightDelta;
      }
    },
    onMouseover(i) {
      this.arrowCounter = i;
    },
    onArrowDown() {
      if (this.arrowCounter + 1 < this.results.length) {
        this.arrowCounter = this.arrowCounter + 1;
        this.tempValue = this.results[this.arrowCounter].value;
        this.adjustScroll();
      }
    },
    onArrowUp() {
      if (this.arrowCounter > 0) {
        this.arrowCounter = this.arrowCounter - 1;
        this.tempValue = this.results[this.arrowCounter].value;
        this.adjustScroll();
      } else if (this.arrowCounter <= 0) {
        this.tempValue = this.searchTerm;
      }
    },
    onEnter() {
      this.setResult(this.results[this.arrowCounter]);
      this.arrowCounter = -1;
    },
    handleClickOutside(evt) {
      if (!this.$el.contains(evt.target)) {
        this.tempValue = null;
        this.isOpen = false;
        this.arrowCounter = -1;
      }
    },
    onEsc() {
      this.tempValue = null;
      this.isOpen = false;
      this.arrowCounter = -1;
    },
  },
  computed: {
    inputValue() {
      if (this.tempValue !== null) {
        return this.tempValue;
      } else if (this.currentCountry !== null) {
        return this.countries.find(item => item.code === this.currentCountry).value;
      } else if (this.searchTerm !== null) {
        return this.searchTerm;
      } else {
        return '';
      }
    },
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  destroyed() {
    document.removeEventListener('click', this.handleClickOutside);
  },
};