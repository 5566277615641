import forOwn from 'lodash.forown';
const GLOBAL_CONFIG = window.jsconfig || {};
const LIST_OF_COUNTRIES = window.listOfCountries || [];
import keySort from '../util/keySort';


export default {
  init(args) {
    const { context, config, STORE } = args;

    const State = {
      countries: [],
    };

    const Event = {
      RENDER: 'zf.render',
    };

    const ClassName = {
      REMOVE_ITEM: 'js-removeCountry',
    };

    const Selector = {
      ZONE_FINDER_SEARCH: '.js-zoneFinderSearch',
      ZONE_FINDER_SEARCH_INPUT: '.js-zoneFinderSearch_input',
      TABLE: '.js-countryZoneTable',
      REMOVE_ITEM: '.' + ClassName.REMOVE_ITEM,
      ZONE_SUGGESTION: '.js-zoneSuggestion',
      ZONE_SELECT_LINK: '.js-selectZoneLink',
    };

    const $elements = {
      ZONE_FINDER_SEARCH: $(context).find(Selector.ZONE_FINDER_SEARCH),
      ZONE_FINDER_SEARCH_INPUT: $(context).find(Selector.ZONE_FINDER_SEARCH_INPUT),
      TABLE: $(context).find(Selector.TABLE),
      ZONE_SUGGESTION: $(context).find(Selector.ZONE_SUGGESTION),
    };

    const CurrentProductType = $(context).data('js-product-type');

    const addCountryToState = country => {
      if (!State.countries.find(elem => elem.id === country.id)) {
        State.countries.push(country);
        $(context).trigger(Event.RENDER);
      }
    };

    const removeCountryFromState = id => {
      State.countries = State.countries.filter(country => {
        return country.id !== id;
      });
      $(context).trigger(Event.RENDER);
    };

    const commonZones = countries => {
      let matchingZones = [];

      const doAllCountriesShareZone = zone => {
        let answer = true;

        if (countries.find(country => !(country.zones).includes(zone))) {
          answer = false;
        }
        return answer;
      };

      // the order of base zones determines the first suggestion
      const baseZones = ['zone-a', 'zone-b', 'zone-c'];
      const isBaseZone = zone => {
        return baseZones.indexOf(zone) > -1;
      };

      // find first common base zone
      const baseZone = baseZones.find(zone => {
        return doAllCountriesShareZone(zone);
      });

      if (baseZone) {
        matchingZones.push(baseZone);
      }

      // find other matching zones
      forOwn(GLOBAL_CONFIG.zones[CurrentProductType], zone => {
        if (!isBaseZone(zone.slug)) {
          if (doAllCountriesShareZone(zone.slug)) {
            matchingZones.push(zone.slug);
          }
        }
      });

      return matchingZones;
    };

    const suggestionTextController = () => {
      const zones = commonZones(State.countries);
      let suggestionText = GLOBAL_CONFIG.strings.chooseZone;

      if (State.countries.length > 0) {
        if (zones.length) {
          let zoneLinks = zones.map(zone => {

            if (!getZoneInfo(zone)) {
              return '';
            }

            return '<a href="#" class="js-selectZoneLink" data-zone-id="' + zone + '">' + getZoneInfo(zone).displayName + '</a>';
          });

          let zonesText = zoneLinks.join(' ' + GLOBAL_CONFIG.strings.or + ' ');

          suggestionText = suggestionText + ' ' + zonesText;

          $elements.ZONE_SUGGESTION.html(suggestionText);
          $elements.ZONE_SUGGESTION.show();
        } else {
          $elements.ZONE_SUGGESTION.hide();
        }
      } else {
        $elements.ZONE_SUGGESTION.hide();
      }
    };

    const tableRowTemplate = (content1, content2) => {
      const icon = '<svg viewBox="0 0 12 12"><path d="M4.605 6L0 10.605 1.395 12 6 7.395 10.605 12 12 10.605 7.395 6 12 1.395 10.605 0 6 4.605 1.395 0 0 1.395 4.605 6z"></path></svg>';
      return '<tr>' +
        '<td>' + content1 + '</td>' +
        '<td>' + content2 + '</td>' +
        '<td class="table_closeCell ' + ClassName.REMOVE_ITEM + '"><div class="closeIcon">' + icon + '</div></td>' +
        '</tr>';
    };

    const getZoneInfo = id => GLOBAL_CONFIG.zones[CurrentProductType][id];

    const rowRenderer = (country) => {
      let content1 = country.value;
      let content2 = GLOBAL_CONFIG.strings.countryNotSupported || '';

      if (country.zones.length) {
        let zoneNames = [];
        country.zones.forEach(zone => {

          if (!getZoneInfo(zone)) {
            return;
          }

          zoneNames.push(getZoneInfo(zone).shortName);
        });
        content2 = zoneNames.join(', ');
      }

      $elements.TABLE.find('tbody').append($(tableRowTemplate(content1, content2)).attr('data-id', country.id));
    };

    const tableRenderController = (rowRenderer, state) => {
      if (state.countries.length > 0) {
        $elements.TABLE.find('tbody').html('');
        state.countries.forEach((country) => {
          rowRenderer(country);
          $elements.TABLE.show();
        });
      } else {
        $elements.TABLE.hide();
      }
    };

    $(context).on(Event.RENDER, function () {
      suggestionTextController();
      tableRenderController(rowRenderer, State);
    });

    $elements.TABLE.on('click', Selector.REMOVE_ITEM, function (e) {
      removeCountryFromState($(e.currentTarget).closest('tr').data('id'));
    });


    $elements.ZONE_FINDER_SEARCH.each(function () {
      const container = this;
      const input = $(container).find(Selector.ZONE_FINDER_SEARCH_INPUT);
      const countries = window.listOfCountries;
      const globalConfig = window.countrySearchConfig || {};
      const options = {
        lookup: countries.sort(keySort('value')),
        lookupFilter: function (suggestion, originalQuery, queryLowerCase) {
          return suggestion.value.toLowerCase().indexOf(queryLowerCase) === 0;
        },
        appendTo: $(container),
        showNoSuggestionNotice: true,
        noSuggestionNotice: 'No results',
        maxHeight: 200,
        triggerSelectOnValidInput: false,
        autoSelectFirst: true,
        onSelect: function (country) {
          addCountryToState(country);
          $(input).val('');
        },
      };

      const config = $.extend({}, options, globalConfig);

      if (!countries) {
        return;
      }

      $(input).autocomplete(config);
    });

    $(context).on('click', Selector.ZONE_SELECT_LINK, function (e) {
      e.preventDefault();
      const $this = $(e.target);
      const zone = $this.data('zone-id');
      let bundleItem = null;
      forOwn(STORE.getState().bundledItems, item => {
        if (item.zone === zone) {
          bundleItem = item;
        }
      });

      STORE.dispatch({
        type: 'UPDATE_PENDING_DATA_PLAN',
        data: bundleItem.default_variation,
      });

      STORE.dispatch({
        type: 'UPDATE_PENDING_DATA_BUNDLE',
        data: bundleItem.id,
      });
    });

    const loadInitialState = config => {
      /* preSelectedCountries contains only country id's
      * but we have to get the full country data from the LIST_OF_COUNTRIES
      * to populate the countries state
      */
      const { preSelectedCountries } = config;
      let countries = [];

      LIST_OF_COUNTRIES.forEach(country => {
        preSelectedCountries.forEach(id => {
          if (country.id === id) {
            countries.push(country);
          }
        });
      });

      countries.forEach(country => {
        addCountryToState(country);
      });
    };

    loadInitialState(config);

  },
};
