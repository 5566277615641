export default {
  init(args) {

    const { context } = args;

    const navSlider = '.js-slick-navSlider';
    const mainSlider = '.js-slick-testimonialSlider';
    const navSliderElem =  $(context).find(navSlider)[0];
    const mainSliderElem =  $(context).find(mainSlider)[0];
    const dataTarget = 'target-slide';

    $(navSliderElem).slick({
      variableWidth: true,
      slidesToShow: 1,
      infinite: false,
      arrows: false,
      asNavFor: mainSliderElem,
      draggable: false,
      focusOnSelect: false,
    });

    $(mainSliderElem).slick({
      slidesToShow: 1,
      infinite: false,
      arrows: false,
      fade: true,
      draggable: false,
      asNavFor: navSliderElem,
      focusOnSelect: false,
    });
    
    $(context).on('click', navSlider + ' ' + '[data-' + dataTarget + ']', function (e) {
      e.preventDefault();
      $(navSliderElem).slick('slickGoTo', $(e.target).data(dataTarget));
    });

    $(context).on('click', mainSlider + ' ' + '[data-' + dataTarget + ']', function (e) {
      e.preventDefault();
      let target = $(e.target).data(dataTarget);

      if (target === 'next') {
        let slider = $(mainSliderElem).slick('getSlick');
        let slickCurrentSlide = $(mainSliderElem).slick('slickCurrentSlide');
        if (slickCurrentSlide === slider.$slides.length - 1) {
          $(mainSliderElem).slick('slickGoTo', 0);
        } else {
          $(mainSliderElem).slick('slickNext');
        }
      }
    });
  },
};

