export default () => {
  const $container = $('.js-combinedSelector');
  const $item = $('.js-combinedSelector_item');
  const $select = $('.js-combinedSelector_select');

  $select.not(":first").hide();

  $item.click(function () {
    const version = $(this).attr('data-version');
    const $version = $('#' + version);

    $item.removeClass('active');
    const inside = $(this).html();
    $(this).parents($container).find('.js-combinedSelector_toggle').html(inside);
    $(this).addClass('active');

    if ($version.length) {
      $select.hide();
      $version.show();
    }
  });
};
