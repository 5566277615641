import Util from '../util/util';


const OverMenu = (($) => {
  /**
   * ------------------------------------------------------------------------
   * Constants
   * ------------------------------------------------------------------------
   */

  const NAME = 'overMenu';
  const VERSION = '1.0.0';
  const DATA_KEY = 'ts.overMenu';
  const EVENT_KEY = `.${DATA_KEY}`;
  const DATA_API_KEY = '.data-api';
  const JQUERY_NO_CONFLICT = $.fn[NAME];
  const TRANSITION_DURATION = 300;
  const ESCAPE_KEYCODE = 27; // KeyboardEvent.which value for Escape (Esc) key

  const Default = {
    keyboard: true,
    focus: true,
    show: true,
  };

  const DefaultType = {
    keyboard: 'boolean',
    focus: 'boolean',
    show: 'boolean',
  };

  const Event = {
    HIDE: `hide${EVENT_KEY}`,
    HIDDEN: `hidden${EVENT_KEY}`,
    SHOW: `show${EVENT_KEY}`,
    SHOWN: `shown${EVENT_KEY}`,
    FOCUSIN: 'focusin.bs.modal', // This event name is shared accross all autofocusing elements. Common event name enables those elements to override it and prevent focus loops.
    RESIZE: `resize${EVENT_KEY}`,
    CLICK_DISMISS: `click.dismiss${EVENT_KEY}`,
    KEYDOWN_DISMISS: `keydown.dismiss${EVENT_KEY}`,
    MOUSEUP_DISMISS: `mouseup.dismiss${EVENT_KEY}`,
    MOUSEDOWN_DISMISS: `mousedown.dismiss${EVENT_KEY}`,
    CLICK_DATA_API: `click${EVENT_KEY}${DATA_API_KEY}`,
  };

  const ClassName = {
    OPEN: 'st-overMenu-open',
    SHOW: 'st-show',
  };

  const Selector = {
    DIALOG: '.js-overMenu_dialog',
    DATA_TOGGLE: '[data-toggle="overMenu"]',
    DATA_DISMISS: '[data-dismiss="overMenu"]',
  };

  /**
 * ------------------------------------------------------------------------
 * Class Definition
 * ------------------------------------------------------------------------
 */

  class OverMenu {
    constructor(element, config) {
      this._config = this._getConfig(config);
      this._element = element;
      this._dialog = $(element).find(Selector.DIALOG)[0];
      this._isShown = false;
      this._isTransitioning = false;
    }

    // getters

    static get VERSION() {
      return VERSION;
    }

    static get Default() {
      return Default;
    }

    // public

    toggle(relatedTarget) {
      return this._isShown ? this.hide() : this.show(relatedTarget);
    }

    show(relatedTarget) {
      if (this._isTransitioning) {
        return false;
      }
      if (Util.supportsTransitionEnd()) {
        this._isTransitioning = true;
      }
      const showEvent = $.Event(Event.SHOW, {
        relatedTarget,
      });

      $(this._element).trigger(showEvent);

      if (this._isShown || showEvent.isDefaultPrevented()) {
        return;
      }

      this._isShown = true;

      $(document.body).addClass(ClassName.OPEN);

      this._setEscapeEvent();

      $(this._element).on(
        Event.CLICK_DISMISS,
        Selector.DATA_DISMISS,
        (event) => this.hide(event)
      );

      this._showElement(relatedTarget);
    }

    hide(event) {
      if (event) {
        event.preventDefault();
      }
      
      $(document.body).removeClass(ClassName.OPEN);

      if (this._isTransitioning) {
        return false;
      }

      const transition = Util.supportsTransitionEnd();
      if (transition) {
        this._isTransitioning = true;
      }

      const hideEvent = $.Event(Event.HIDE);
      $(this._element).trigger(hideEvent);

      if (!this._isShown || hideEvent.isDefaultPrevented()) {
        return;
      }

      this._isShown = false;

      this._setEscapeEvent();

      $(document).off(Event.FOCUSIN);

      $(this._element).removeClass(ClassName.SHOW);

      $(this._element).off(Event.CLICK_DISMISS);
      $(this._dialog).off(Event.MOUSEDOWN_DISMISS);

      if (transition) {
        $(this._element)
          .one(Util.TRANSITION_END, (event) => this._hideElement(event))
          .emulateTransitionEnd(TRANSITION_DURATION);
      } else {
        this._hideElement();
      }
    }

    // private

    _getConfig(config) {
      config = $.extend({}, Default, config);
      Util.typeCheckConfig(NAME, config, DefaultType);
      return config;
    }

    _showElement(relatedTarget) {
      const transition = Util.supportsTransitionEnd();

      if (!this._element.parentNode ||
        this._element.parentNode.nodeType !== Node.ELEMENT_NODE) {
        // don't move modals dom position
        document.body.appendChild(this._element);
      }

      this._element.style.display = 'block';
      this._element.removeAttribute('aria-hidden');
      this._element.scrollTop = 0;

      if (transition) {
        Util.reflow(this._element);
      }

      $(this._element).addClass(ClassName.SHOW);

      if (this._config.focus) {
        this._enforceFocus();
      }

      const shownEvent = $.Event(Event.SHOWN, {
        relatedTarget,
      });

      const transitionComplete = () => {
        if (this._config.focus) {
          this._element.focus();
        }
        this._isTransitioning = false;
        $(this._element).trigger(shownEvent);
      };

      if (transition) {
        $(this._dialog)
          .one(Util.TRANSITION_END, transitionComplete)
          .emulateTransitionEnd(TRANSITION_DURATION);
      } else {
        transitionComplete();
      }
    }

    _enforceFocus() {
      $(document)
        .off(Event.FOCUSIN) // guard against infinite focus loop
        .on(Event.FOCUSIN, (event) => {
          if (document !== event.target &&
            this._element !== event.target &&
            !$(this._element).has(event.target).length) {
            this._element.focus();
          }
        });
    }

    _setEscapeEvent() {
      if (this._isShown && this._config.keyboard) {
        $(this._element).on(Event.KEYDOWN_DISMISS, (event) => {
          if (event.which === ESCAPE_KEYCODE) {
            this.hide();
          }
        });
      } else if (!this._isShown) {
        $(this._element).off(Event.KEYDOWN_DISMISS);
      }
    }

    _hideElement() {
      this._element.style.display = 'none';
      this._element.setAttribute('aria-hidden', 'true');
      this._isTransitioning = false;
      $(this._element).trigger(Event.HIDDEN);
    }


    // static

    /*
      _jQueryInterface loops over all the matching elements
    */
    static _jQueryInterface(config, relatedTarget) {
      return this.each(function () {
        let data = $(this).data(DATA_KEY);
        const _config = $.extend(
          {},
          OverMenu.Default,
          $(this).data(),
          typeof config === 'object' && config
        );

        if (!data) {
          data = new OverMenu(this, _config);
          $(this).data(DATA_KEY, data);
        }

        if (typeof config === 'string') {
          if (data[config] === undefined) {
            throw new Error(`No method named "${config}"`);
          }
          data[config](relatedTarget);
        }  else if (_config.show) {
          data.show(relatedTarget);
        }
      });
    }
  }



  /**
   * ------------------------------------------------------------------------
   * Data Api implementation
   * ------------------------------------------------------------------------
   */

  $(document).on(Event.CLICK_DATA_API, Selector.DATA_TOGGLE, function (event) {
    let target;
    const selector = Util.getSelectorFromElement(this);

    if (selector) {
      target = $(selector)[0];
    }

    const config = $(target).data(DATA_KEY) ?
      'toggle' : $.extend({}, $(target).data(), $(this).data());

    if (this.tagName === 'A' || this.tagName === 'AREA') {
      event.preventDefault();
    }

    const $target = $(target).one(Event.SHOW, (showEvent) => {
      if (showEvent.isDefaultPrevented()) {
        // only register focus restorer if modal will actually get shown
        return;
      }

      $target.one(Event.HIDDEN, () => {
        if ($(this).is(':visible')) {
          this.focus();
        }
      });
    });

    OverMenu._jQueryInterface.call($(target), config, this);
  });



  /**
 * ------------------------------------------------------------------------
 * jQuery
 * ------------------------------------------------------------------------
 */

  /* 
    OverMenu._jQueryInterface is a static method and is therefore only accessible 
    directly on the OverMenu class
    $.fn.overMenu = OverMenu._jQueryInterface -
    basiaclly we are assigning a function to the $.prototype.overMenu here

  */
  $.fn[NAME] = OverMenu._jQueryInterface;
  $.fn[NAME].Constructor = OverMenu;
  $.fn[NAME].noConflict = function () {
    $.fn[NAME] = JQUERY_NO_CONFLICT;
    return OverMenu._jQueryInterface;
  };

  return OverMenu;

})(jQuery);

export default OverMenu;