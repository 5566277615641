import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger.js";

export default () => {
  gsap.registerPlugin(ScrollTrigger);

  const breakpoint = window.matchMedia("(min-width: 768px)");

  gsap.utils.toArray('.js-revealUp').forEach(function (elem) {

    if(breakpoint.matches) {

      ScrollTrigger.create({
        trigger: elem,
        start: 'top 80%',
        end: 'bottom 20%',
        markers: false,
        onEnter: function () {
          gsap.fromTo(
            elem,
            {y: 100, autoAlpha: 0},
            {
              duration: 1.25,
              y: 0,
              autoAlpha: 1,
              ease: 'back',
              overwrite: 'auto',
            }
          );
        },
        onLeave: function () {
          gsap.fromTo(elem, {autoAlpha: 1}, {autoAlpha: 0, overwrite: 'auto'});
        },
        onEnterBack: function () {
          gsap.fromTo(
            elem,
            {y: -100, autoAlpha: 0},
            {
              duration: 1.25,
              y: 0,
              autoAlpha: 1,
              ease: 'back',
              overwrite: 'auto',
            }
          );
        },
        onLeaveBack: function () {
          gsap.fromTo(elem, {autoAlpha: 1}, {autoAlpha: 0, overwrite: 'auto'});
        },
      });

    }

  });
};
