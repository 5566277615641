export default () => {
    var Selector = {
        PAYMENT_TAB: 'label[for=payment_method_rsc]',
        PAYMENT_FIELDS: '.payment_box.payment_method_rsc fieldset',
    };

    var needsInitializing = false;

    $('.woocommerce-checkout').on('updated_checkout', function () {
        if (!window.vars.isLoggedIn){
            return;
        }

        if (!needsInitializing){
            return;
        }
        //console.log('Sending RSC Ajax Request');
        $(Selector.PAYMENT_FIELDS).text('Loading...');
        var ajaxRequest = $.ajax({
            method: 'GET',
            url: window.ajaxurl,
            data: {
                action: 'echo_rsc_html',
            },
        });
        ajaxRequest.done(function ($request) {
            //console.log('REQUEST SUCCESS!');
            $(Selector.PAYMENT_FIELDS).html($request);
            needsInitializing = false;
            //console.log($request);
        });
        ajaxRequest.fail(function ($request) { // eslint-disable-line no-unused-vars
            //console.log('REQUEST FAILED!');
            //console.log($request);
        });
    });
};
