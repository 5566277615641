import queryString from 'query-string';

export default () => {
  const parsed = queryString.parse(location.search);
  if (parsed.modal) {
    $('#' + parsed.modal).modal('show');

    // ToDo: Find a solution to this backtracking problem.
    //window.history.replaceState({}, document.title, window.location.href.split('?')[0]);
  }
};
