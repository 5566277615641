export default () => {
  const $toggle = $('.js-tt-checkoutGrid_toggle');
  const $dropdown = $('.js-tt-checkoutGrid_dropdown');

  $(document).ready(function () {
    $toggle.on('click', function (e) {
      e.preventDefault();
      $dropdown.slideToggle();
      $(this).toggleClass('open');
    });
  });

};
