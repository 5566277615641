export default () => {
  const $tabs = $('.js-autoTabs a');
  const $langMenu = $('.langCur');

  let currTab = 0;
  const totalTabs = $tabs.length;

  function cycle() {

    if(!$langMenu.hasClass('show')) {
      $tabs.eq(currTab).click();
    }

    currTab++;

    if (currTab === totalTabs) {
      currTab = 0;
    }
  }

  setInterval(cycle, 3500);

};
