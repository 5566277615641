export default function (testElement, property, expectedValue) {
  // detect when the main stylesheet has loaded
  let timeLapsed = 0;
  let fired = false;
  const intervalDuration = 50;
  window.mainStylesheetHasLoaded = false;

  let currentInterval = window.setInterval(function() {
    timeLapsed += intervalDuration;
    if (window.getComputedStyle(testElement)[property] === expectedValue) {
      window.clearInterval(currentInterval);
      document.dispatchEvent(new CustomEvent('mainStylesheetLoaded'));
      window.mainStylesheetHasLoaded = true;
    }

    // stop after 3 seconds even if the stylesheet has not been loaded
    if (timeLapsed >= 3000) {
      if (!fired) {
        window.clearInterval(currentInterval);
        document.dispatchEvent(new CustomEvent('mainStylesheetLoaded'));
        window.mainStylesheetHasLoaded = true;
      }
    }
  }, intervalDuration);
}