/* eslint-disable */
import _cloneDeep from "lodash.clonedeep";
import convertToReadablePrice from "../util/convertToReadablePrice";

const GLOBAL_CONFIG = window.jsconfig || {};
const listOfCountries = window.listOfCountries;

let countryColors = {};
let countryNamesByCode = {};
listOfCountries.forEach(function (item) {
  countryColors[item.code] = "#F2E0BC";
  countryNamesByCode[item.code] = item.value;
});

import Vue from "vue";
import CountryComplete from "../vue-components/country-complete";

export default {
  init(rootId, config) {
    const {
      countries,
      apiEndPoints,
      currency,
      countryDefaultZones,
      zoneVariations,
    } = config;

    const store = {
      state: {
        currentCountry: null,
        currentMap: "europe_mill",
        rates: null,
        isFetchingRates: false,
      },
      actionSetCurrentCountry(code) {
        this.state.currentCountry = code;
      },
      actionSetCurrentMap(map) {
        this.state.currentMap = map;
      },
      actionSetRates(rates) {
        this.state.rates = rates;
      },
      actionFetchingRatesStart() {
        this.state.isFetchingRates = true;
      },
      actionFetchingRatesEnd() {
        this.state.isFetchingRates = false;
      },
    };

    const app = new Vue({
      el: "#" + rootId,
      components: {
        "country-complete": CountryComplete,
      },
      data: store.state,
      mapInstances: [],
      countryColors: countryColors,
      mounted() {
        let _self = this;

        if (!window.mainStylesheetHasLoaded) {
          document.addEventListener("mainStylesheetLoaded", function () {
            window.setTimeout(function () {
              _self.initMap();
            }, 10);
          });
        } else {
          _self.initMap();
        }
      },
      methods: {
        fetchRates: function () {
          store.actionFetchingRatesStart();
          let req = $.getJSON(apiEndPoints.mapRates);
          req.always(function () {
            store.actionFetchingRatesEnd();
          });
          return req;
        },

        initRates: function () {
          if (this.isFetchingRates) {
            return;
          }
          if (!this.rates) {
            let rates = this.fetchRates();
            rates.done(function (rates) {
              store.actionSetRates(rates);
            });
          }
        },
        handleCountrySelect: function (country) {
          if (country) {
            const coveredCountry = listOfCountries.find(
              (item) => item.code === country.code
            );
            if (coveredCountry) {
              this.setMapFocus(country.code);

              store.actionSetCurrentCountry(country.code);

              this.initRates();
            }
          } else {
            store.actionSetCurrentCountry(null);
            this.mapInstances.forEach(function (instance) {
              instance.reset();
              instance.series.regions[0].setValues(countryColors);
            });
          }
        },
        handleMapSelect(map) {
          store.actionSetCurrentMap(map);
        },
        onDestinationClick: function (countryCode) {
          this.handleCountrySelect({ code: countryCode });
        },
        setMapFocus(countryCode) {
          const _self = this;
          let mapThatHasTheCountry = this.mapInstances.find(function (
            instance
          ) {
            if (_self.currentMap === "world_mill") {
              return instance.params.map === "world_mill";
            } else {
              return !!instance.regions[countryCode];
            }
          });

          // no need to switch maps if the world map is already active, as it has all the countries
          if (this.currentMap !== "world_mill") {
            store.actionSetCurrentMap(mapThatHasTheCountry.params.map);
          }

          mapThatHasTheCountry.setFocus({
            region: countryCode,
            animate: true,
            offsetRegionX: 1,
            offsetRegionY: 0.1,
          });

          const newCountryColors = Object.assign({}, countryColors);
          newCountryColors[countryCode] = "#FF5000";
          this.mapInstances.forEach(function (instance) {
            instance.series.regions[0].setValues(newCountryColors);
          });
        },
        initMap() {
          let _self = this;
          if (!_self.mapInstances) {
            _self.mapInstances = [];
          }
          $(_self.$refs.maps)
            .find("[data-svg-map]")
            .each(function (index, element) {
              const instance = $(element).vectorMap({
                map: $(element).data("svg-map"),
                onRegionClick: function (e, code) {
                  _self.handleCountrySelect({ code: code });
                },
                onRegionTipShow: function (e, tip, code) {
                  tip.html(countryNamesByCode[code]);
                },
                backgroundColor: "none",
                regionStyle: {
                  initial: {
                    fill: "#D3D3D3",
                  },
                },
                series: {
                  regions: [
                    {
                      attribute: "fill",
                      values: countryColors,
                    },
                  ],
                },
              });
              _self.mapInstances.push(instance.vectorMap("get", "mapObject"));
            });
        },
      },
      computed: {
        currentCountryName() {
          if (this.currentCountry) {
            return countries.find((item) => item.code === this.currentCountry)
              .value;
          } else {
            return "";
          }
        },
        physicalSimCurrentDataPackage() {
          let dataPackage = null;
          let zone = null;
          let zoneId = null;
          if (this.currentCountry) {
            zone = countryDefaultZones[this.currentCountry];
            if (zone && zone.physicalSim) {
              if (zone.physicalSim.id) {
                zoneId = zone.physicalSim.id.toString();
              }
            }

            if (zoneId && zoneVariations[zoneId]) {
              dataPackage = _cloneDeep(zoneVariations[zoneId]);
              dataPackage.values = dataPackage.values.map(function (item) {
                item.price = convertToReadablePrice(item.price);
                return item;
              });
            }
          }
          return dataPackage;
        },

        eSimCurrentDataPackage() {
          let dataPackage = null;
          let zone = null;
          let zoneId = null;
          if (this.currentCountry) {
            zone = countryDefaultZones[this.currentCountry];
            if (zone && zone.eSim) {
              if (zone.eSim.id) {
                zoneId = zone.eSim.id.toString();
              }
            }
            if (zoneId && zoneVariations[zoneId]) {
              dataPackage = _cloneDeep(zoneVariations[zoneId]);
              dataPackage.values = dataPackage.values.map(function (item) {
                item.price = convertToReadablePrice(item.price);
                return item;
              });
            }
          }

          return dataPackage;
        },

        eSimAsiaCurrentDataPackage() {
          let dataPackage = null;
          let zone = null;
          let zoneId = null;
          if (this.currentCountry) {
            zone = countryDefaultZones[this.currentCountry];
            if (zone && zone.eSimAsia) {
              if (zone.eSimAsia.id) {
                zoneId = zone.eSimAsia.id.toString();
              }
            }
            if (zoneId && zoneVariations[zoneId]) {
              dataPackage = _cloneDeep(zoneVariations[zoneId]);
              dataPackage.values = dataPackage.values.map(function (item) {
                item.price = convertToReadablePrice(item.price);
                return item;
              });
            }
          }

          return dataPackage;
        },

        currentCountryRates() {
          if (this.currentCountry && this.rates) {
            let currentRates = _cloneDeep(this.rates[this.currentCountry]);
            currentRates = currentRates[currency.code];

            for (let product in currentRates) {
              if (currentRates.hasOwnProperty(product)) {
                for (let rate in currentRates[product]) {
                  if (
                    currentRates[product].hasOwnProperty(rate) &&
                    currentRates[product][rate] !== null
                  ) {
                    currentRates[product][rate] = convertToReadablePrice(
                      currentRates[product][rate]
                    );
                  }
                }
              }
            }

            return currentRates;
          }

          return null;
        },
      },
    });
  },
};
/* eslint-enable */
