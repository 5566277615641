
import mobileMenu from '../snippets/mobileMenu';
import stickyHeader from '../snippets/stickyHeader';
import countryComplete from '../snippets/countryComplete';
import destinationsComplete from '../snippets/destinationsComplete';
import zonesModal from '../snippets/zonesModal';
import stickyPanel from '../snippets/stickyPanel';
import truncate from '../snippets/truncate';
import queryModals from '../snippets/queryModals';
import cartLink from '../snippets/cartLink';
import callLog from '../snippets/callLog';
import reuseSimCreditGateway from '../snippets/reuseSimCreditGateway';
import countryListColumns from '../snippets/countryListColumns';
import exitOffer from '../snippets/exitOffer';
import simLinker from '../snippets/simLinker';
import adyenHppCheckout from '../snippets/adyenHppCheckout';
import introSlider from '../snippets/introSlider';
import myAccount from '../snippets/myAccount';
import myCorporateAccount from '../snippets/myCorporateAccount';
import toastModal from '../snippets/toastModal';
import totalTable from '../snippets/totalTable';
import addedToCartToast from '../snippets/addedToCartToast';
import addedToCartPopup from '../snippets/addedToCartPopup';
import combinedSelector from '../snippets/combinedSelector';
import tt_setupSlider from '../2023-design/tt_setupSlider';
import tt_typePlaceholder from '../2023-design/tt_typePlaceholder';
import tt_upScrollButton from '../2023-design/tt_upScrollButton';
import tt_autoTabs from '../2023-design/tt_autoTabs';
import tt_planBuilder from '../2023-design/tt_planBuilder';
// import checkoutPaypal from "../snippets/checkoutPaypal";
import tt_checkoutGrid from "../2023-design/tt_checkoutGrid";

export default {
  init() {

    // JavaScript to be fired on all pages

    // init mobile menu related functionality
    mobileMenu();

    // init scrollSticky
    stickyHeader();

    // init matchHeight
    $('.js-matchHeight').matchHeight();

    // init autocomplete for country search fields
    countryComplete();

    // init autocomplete for destinations search fields
    destinationsComplete();

    // zones modal funcionality
    zonesModal();

    // sticky panels
    stickyPanel();

    // truncate
    truncate();

    // url query modals
    queryModals();

    // handle links to cart
    cartLink();

    // call log page
    callLog();

    // reuse sim credit Gateway
    reuseSimCreditGateway();

    // sorts countries into equal columns
    countryListColumns();

    // functionality for adding sim card to account
    simLinker();

    adyenHppCheckout();

    // init introSlider
    introSlider();

    // initial ajax load cards
    myAccount();

    // initial corporate scripts
    myCorporateAccount();

    // init toastModal
    toastModal();
    // init totalTable mobile toggle
    totalTable();

    // init added to cart toast
    addedToCartToast();
    addedToCartPopup();

    // init combinedSelector
    combinedSelector();

    // init tt_setupSlider
    tt_setupSlider();

    tt_typePlaceholder();

    tt_upScrollButton();

    tt_autoTabs();

    tt_planBuilder();

    tt_checkoutGrid();

    // init exit offer
    if (window.jsconfig.enableExitOffer) {
      exitOffer();
    }

    // Handle cart update
    $(document.body).on('update_checkout', function() {
      $('.js-cart-container').block({
        message: '',
        overlayCSS: {
          background: '#fff',
          opacity: 0.6,
        },
      });
    });

    // $(document).on('click', '#payment', function () {
    //   checkoutPaypal()
    // })

    $(document.body).on('updated_checkout', function() {
      $.ajax({
        method: 'GET',
        url: window.ajaxurl,
        data: { action: 'travelsim_update_cart' },
      }).done(function(response) {
        $('.js-cart-container').html(response);
        $('.js-cart-container').removeClass( 'processing' ).unblock();
      });
    });

    $(document).on('click', '.woocommerce-remove-coupon', function(){
      $(document.body).trigger('update_checkout');
    });

    $(document).on('click', '.coupon_body #apply_coupon', function(){
      $(document.body).trigger('update_checkout');
    });

    $(document).on('change', 'input[name="payment_method"]', function() {
      $(document.body).trigger('update_checkout');
    });

    $(function(){
      $( 'form.checkout, form#order_review').on( 'change', 'select#billing_country', function() {
        var country         = $('select#billing_country').val();
        var check_countries = ['AR'];

        if ( country && $.inArray( country, check_countries ) >= 0 ) {
          $('.only-in-ar').fadeIn();
        } else {
          $('.only-in-ar').fadeOut();
        }
      });
      $('select#billing_country').change();
    });

    $(document).on('click', '.js-changeLanguageAndCurrency', function() {
      var $form = $(this).closest('form');

      var $radio = $form.find('.tickRadio_radio[name=lang]:checked');
      $form.attr('action', $radio.data('url'));
      $radio.removeAttr('name');
    });

    // front intro special list collapse plus to minus animation classes
    $('.js-specialListCollapse .collapse').on('show.bs.collapse', function () {
      $(this).parent('.js-specialListCollapse').find('.plusMinus').addClass('minus');
    });
    $('.js-specialListCollapse .collapse').on('hide.bs.collapse', function () {
      $(this).parent('.js-specialListCollapse').find('.plusMinus').removeClass('minus');
    });

    // SIM Card labels
    $(document).on('click', '.js-sim-label-edit', function (e) {
      var $parent = $(this).closest('.js-label-container');
      $parent.find('.js-sim-label, .js-sim-label-empty').hide();
      $parent.find('.js-sim-label-form').show();

      e.preventDefault();
    });

    $(document).on('click', '.js-sim-label-submit', function (e) {
      var $parent = $(this).closest('.js-label-container');
      var value = $parent.find('.js-sim-label-field').val();

      value = sanitize(value)

      if (value) {
        $parent.find('.js-sim-label-field').html(value);
        $parent.find('.js-sim-label-value').html(value);
        $parent.find('.js-sim-label').show();
        $parent.find('.js-sim-label-form').hide();
      } else {
        $parent.find('.js-sim-label-form').hide();
        $parent.find('.js-sim-label-empty').show();
      }

      $.ajax({
        type: "POST",
        url: window.ajaxurl,
        data: {
          action: 'travelsim_update_label',
          number: $parent.data('number'),
          label: value,
        },
      });

      e.preventDefault();
    });

    var sanitize = function(string) {
      const map = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#x27;',
        "/": '&#x2F;',
      };
      const reg = /[&<>"'/]/ig;
      return string.replace(reg, (match) => (map[match]));
    }

    /**
     * Hack to display extra line of text for voice package selection
     */
     var toggleVoicePackageText = function() {
        if ($('.js-voiceButton').hasClass('is-active')) {
          $('.js-voicePackageText').show();
        } else {
          $('.js-voicePackageText').hide();
        }
     };
     toggleVoicePackageText();

     $(document).on('click', '.js-airtimeSelection button', function() {
      toggleVoicePackageText();
     });

    // smooth scrolling to anchor in articles
    // https://css-tricks.com/snippets/jquery/smooth-scrolling/#article-header-id-1
    //
    $('.js-anchorSmoothScroll a[href*="#"]')
      // Remove links that don't actually link to anything
      .not('[href="#"]')
      .not('[href="#0"]')
      .click(function(event) {
        // On-page links
        if (
          location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '')
          &&
          location.hostname === this.hostname
        ) {
          // Figure out element to scroll to
          var target = $(this.hash);
          target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
          // Does a scroll target exist?
          if (target.length) {
            // Only prevent default if animation is actually gonna happen
            event.preventDefault();
            $('html, body').animate({
              scrollTop: target.offset().top - 165,
            }, 1000, function() {
              // Callback after animation
              // Must change focus!
              var $target = $(target);
              $target.focus();
              if ($target.is(":focus")) { // Checking if the target was focused
                return false;
              } else {
                $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
                $target.focus(); // Set focus again
              }
            });
          }
        }
      });

  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
