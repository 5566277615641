export default () => {
  const $slider = $('.js-slick-introSlider');
  const $prevArrow = $slider.parent('.introSlider').find('.introSlider_prev');
  const $nextArrow = $slider.parent('.introSlider').find('.introSlider_next');
  const $dots = $slider.parent('.introSlider').find('.introSlider_dots');

  $slider.slick({
    rows: 1,
    slidesToShow: 1,
    infinite: true,
    arrows: true,
    dots: true,
    fade: true,
    draggable: false,
    focusOnSelect: false,
    prevArrow: $prevArrow,
    nextArrow: $nextArrow,
    appendDots: $dots,
    autoplay: true,
    autoplaySpeed: 6000,
    speed: 1500,
  });

  $slider.on('setPosition', () => {
    $slider.find('.introSlider_slide').matchHeight();
  })

  // disable slider on mobile for A/B testing
  //
  const $window = $(window);
  const $sliderTest = $('.js-slick-introSlider-test');
  const $prevArrowTest = $sliderTest.parent('.introSlider').find('.introSlider_prev');
  const $nextArrowTest = $sliderTest.parent('.introSlider').find('.introSlider_next');
  const $dotsTest = $sliderTest.parent('.introSlider').find('.introSlider_dots');

  let settings = {
    rows: 1,
    slidesToShow: 1,
    infinite: true,
    arrows: true,
    dots: true,
    fade: true,
    draggable: false,
    focusOnSelect: false,
    prevArrow: $prevArrowTest,
    nextArrow: $nextArrowTest,
    appendDots: $dotsTest,
    autoplay: true,
    autoplaySpeed: 6000,
    speed: 1500,
  };
  $sliderTest.slick(settings);

  $window.on('resize', function() {
    if ($window.width() < 576) {
      if ($sliderTest.hasClass('slick-initialized'))
        $sliderTest.slick('unslick');
      return
    }
    if (!$sliderTest.hasClass('slick-initialized'))
      return $sliderTest.slick(settings);
  });

};
