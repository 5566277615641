import connectToState from '../store/connector';
import {removeState} from "../util/localStorage";

const GLOBAL_CONFIG = window.jsconfig || {};

export default {
  init: function (args) {

    const {context, STORE} = args;

    const ClassName = {
      ACTIVE: 'is-active',
    };

    const Selector = {
      CALL_CREDIT: '[data-connect="callCredit"]',
      ALTERNATIVE_NUMBER: '[data-connect="alternativeNumber"]',
      DATA_PLAN: '[data-connect="dataPlan"]',
      DATA_PLAN_SELECTOR: '[data-connect="dataPlanSelector"]',
      VOICE_PLAN: '[data-connect="voicePlan"]',
      VOICE_PLAN_SELECTOR: '[data-connect="voicePlanSelector"]',
      AMOUNT_WRAPPER: '[data-connect="simAmountWrapper"]',
      AMOUNT_SELECTOR: '[data-connect="simAmount"]',
      CONFIRM_DATA_PENDING: '[data-connect="confirmPendingDataPlan"]',
      CONFIRM_VOICE_PENDING: '[data-connect="confirmPendingVoicePlan"]',
      DATA_NAV_TAB: '[data-connect="dataBundleTabNav"]',
      VOICE_NAV_TAB: '[data-connect="voiceBundleTabNav"]',
      PENDING_NAV_TAB: '[data-connect="pendingBundleTabNav"]',
      DATA_TAB_CONTENT: '[data-connect="dataBundleTabContent"]',
      VOICE_TAB_CONTENT: '[data-connect="voiceBundleTabContent"]',
      PENDING_TAB_CONTENT: '[data-connect="pendingBundleTabContent"]',
      DATA_PLAN_INFO: '[data-connect="dataPlanInfo"]',
      VOICE_PLAN_INFO: '[data-connect="voicePlanInfo"]',
      SELECTED_DATA_PRICE: '[data-connect="selectedDataPrice"]',
      SELECTED_CALL_PRICE: '[data-connect="selectedCallPrice"]',
      SELECTED_ALTERNATIVE_NUMBER_PRICE: '[data-connect="selectedAlternativeNumberPrice"]',
      SELECTED_VOICE_PRICE: '[data-connect="selectedVoicePrice"]',
      SELECTED_DATA_PRICE_ROW: '[data-connect="selectedDataPriceRow"]',
      SELECTED_CALL_PRICE_ROW: '[data-connect="selectedCallPriceRow"]',
      SELECTED_ALTERNATIVE_NUMBER_PRICE_ROW: '[data-connect="selectedAlternativeNumberPriceRow"]',
      SELECTED_VOICE_PRICE_ROW: '[data-connect="selectedVoicePriceRow"]',
      TOTAL: '[data-connect="total"]',
      ADD_TO_CART: '[data-connect="addToCart"]',
    };

    const $elements = {
      CALL_CREDIT: $(context).find(Selector.CALL_CREDIT),
      ALTERNATIVE_NUMBER: $(context).find(Selector.ALTERNATIVE_NUMBER),
      DATA_PLAN: $(context).find(Selector.DATA_PLAN),
      DATA_PLAN_SELECTOR: $(context).find(Selector.DATA_PLAN_SELECTOR),
      VOICE_PLAN: $(context).find(Selector.VOICE_PLAN),
      VOICE_PLAN_SELECTOR: $(context).find(Selector.VOICE_PLAN_SELECTOR),
      AMOUNT_WRAPPER: $(context).find(Selector.AMOUNT_WRAPPER),
      AMOUNT: $(context).find(Selector.AMOUNT_SELECTOR),
      CONFIRM_DATA_PENDING: $(context).find(Selector.CONFIRM_DATA_PENDING),
      CONFIRM_VOICE_PENDING: $(context).find(Selector.CONFIRM_VOICE_PENDING),
      DATA_NAV_TAB: $(context).find(Selector.DATA_NAV_TAB),
      VOICE_NAV_TAB: $(context).find(Selector.VOICE_NAV_TAB),
      PENDING_NAV_TAB: $(context).find(Selector.PENDING_NAV_TAB),
      DATA_TAB_CONTENT: $(context).find(Selector.DATA_TAB_CONTENT),
      VOICE_TAB_CONTENT: $(context).find(Selector.VOICE_TAB_CONTENT),
      PENDING_TAB_CONTENT: $(context).find(Selector.PENDING_TAB_CONTENT),
      DATA_PLAN_INFO: $(context).find(Selector.DATA_PLAN_INFO),
      VOICE_PLAN_INFO: $(context).find(Selector.VOICE_PLAN_INFO),
      SELECTED_DATA_PRICE: $(context).find(Selector.SELECTED_DATA_PRICE),
      SELECTED_DATA_PRICE_ROW: $(context).find(Selector.SELECTED_DATA_PRICE_ROW),
      SELECTED_CALL_PRICE: $(context).find(Selector.SELECTED_CALL_PRICE),
      SELECTED_ALTERNATIVE_NUMBER_PRICE: $(context).find(Selector.SELECTED_ALTERNATIVE_NUMBER_PRICE),
      SELECTED_CALL_PRICE_ROW: $(context).find(Selector.SELECTED_CALL_PRICE_ROW),
      SELECTED_ALTERNATIVE_NUMBER_PRICE_ROW: $(context).find(Selector.SELECTED_ALTERNATIVE_NUMBER_PRICE_ROW),
      SELECTED_VOICE_PRICE: $(context).find(Selector.SELECTED_VOICE_PRICE),
      SELECTED_VOICE_PRICE_ROW: $(context).find(Selector.SELECTED_VOICE_PRICE_ROW),
      TOTAL: $(context).find(Selector.TOTAL),
      ADD_TO_CART: $(context).find(Selector.ADD_TO_CART),
    };

    let locale = 'en-US';
    if ($('html').attr('lang')) {
      locale = $('html').attr('lang');
    }

    const calcTotal = state => {
      let callCreditPrice = 0;
      const callCreditVariation = state.variations[state.simConfig.callCredit]

      if (callCreditVariation && callCreditVariation.price) {
        callCreditPrice = callCreditVariation.price;
      }

      let alternativeNumberPrice = 0;
      const alternativeNumberVariation = state.variations[state.simConfig.alternativeNumber]

      if (alternativeNumberVariation && alternativeNumberVariation.price) {
        alternativeNumberPrice = alternativeNumberVariation.price;
      }

      let dataPlanPrice = 0;
      const dataVariation = state.variations[state.simConfig.dataPlan];

      if (dataVariation && dataVariation.price) {
        dataPlanPrice = dataVariation.price;
      }

      let voicePlanPrice = 0;
      const voiceVariation = state.variations[state.simConfig.voicePlan];

      if (voiceVariation && voiceVariation.price) {
        voicePlanPrice = voiceVariation.price;
      }

      return (state.simConfig.basePrice + callCreditPrice + alternativeNumberPrice + dataPlanPrice + voicePlanPrice) * state.simConfig.amount;
    };

    const convertToReadablePrice = (cents) => {
      let price = cents / 100;
      return price.toLocaleString(locale, {
        style: "currency",
        currency: GLOBAL_CONFIG.currency.code,
        minimumFractionDigits: 0,
        useGrouping: false,
      });
    };

    $elements.CALL_CREDIT.on('click', function (e) {
      e.preventDefault();
      const $this = $(this);
      STORE.dispatch({
        type: 'UPDATE_CALL_CREDIT',
        data: $this.data('variation-id'),
      });
    });

    $elements.ALTERNATIVE_NUMBER.change(function (e) {
      e.preventDefault();
      const $this = $(this).find('option:selected');
      STORE.dispatch({
        type: 'UPDATE_ALTERNATIVE_NUMBER',
        data: $this.data('variation-id'),
      });
    });

    $elements.DATA_PLAN.on('click', function (e) {
      e.preventDefault();
      const $this = $(this);
      STORE.dispatch({
        type: 'UPDATE_DATA_PLAN',
        data: $this.data('variation-id'),
      });
      STORE.dispatch({
        type: 'UPDATE_PENDING_DATA_PLAN',
        data: $this.data('variation-id'),
      });
    });

    $elements.DATA_PLAN_SELECTOR.on('click', function (e) {
      e.preventDefault();
      const $this = $(this);
      STORE.dispatch({
        type: 'UPDATE_PENDING_DATA_PLAN',
        data: $this.data('variation-id'),
      });
    });

    $elements.CONFIRM_DATA_PENDING.on('click', function (e) {
      e.preventDefault();
      STORE.dispatch({
        type: 'CONFIRM_PENDING_DATA_PLAN',
      });
      STORE.dispatch({
        type: 'CONFIRM_PENDING_DATA_BUNDLE',
      });
    });

    $elements.VOICE_PLAN.on('click', function (e) {
      e.preventDefault();
      const $this = $(this);
      STORE.dispatch({
        type: 'UPDATE_VOICE_PLAN',
        data: $this.data('variation-id'),
      });
      STORE.dispatch({
        type: 'UPDATE_PENDING_VOICE_PLAN',
        data: $this.data('variation-id'),
      });
    });

    $elements.VOICE_PLAN_SELECTOR.on('click', function (e) {
      e.preventDefault();
      const $this = $(this);
      STORE.dispatch({
        type: 'UPDATE_PENDING_VOICE_PLAN',
        data: $this.data('variation-id'),
      });
    });

    $elements.CONFIRM_VOICE_PENDING.on('click', function (e) {
      e.preventDefault();
      STORE.dispatch({
        type: 'CONFIRM_PENDING_VOICE_PLAN',
      });
      STORE.dispatch({
        type: 'CONFIRM_PENDING_VOICE_BUNDLE',
      });
    });

    $elements.DATA_NAV_TAB.on('click', function (e) {
      e.preventDefault();
      const $this = $(this);
      STORE.dispatch({
        type: 'UPDATE_DATA_PLAN',
        data: $this.data('variation-id'),
      });
      STORE.dispatch({
        type: 'UPDATE_DATA_BUNDLE',
        data: $this.data('bundle-item-id'),
      });
    });

    $elements.VOICE_NAV_TAB.on('click', function (e) {
      e.preventDefault();
      const $this = $(this);
      STORE.dispatch({
        type: 'UPDATE_VOICE_PLAN',
        data: $this.data('variation-id'),
      });
      STORE.dispatch({
        type: 'UPDATE_VOICE_BUNDLE',
        data: $this.data('bundle-item-id'),
      });
    });

    $elements.PENDING_NAV_TAB.on('click', function (e) {
      e.preventDefault();
      const $this = $(this);
      STORE.dispatch({
        type: 'UPDATE_PENDING_DATA_PLAN',
        data: $this.data('variation-id'),
      });
      STORE.dispatch({
        type: 'UPDATE_PENDING_DATA_BUNDLE',
        data: $this.data('bundle-item-id'),
      });

      STORE.dispatch({
        type: 'UPDATE_PENDING_VOICE_PLAN',
        data: $this.data('variation-id'),
      });
      STORE.dispatch({
        type: 'UPDATE_PENDING_VOICE_BUNDLE',
        data: $this.data('bundle-item-id'),
      });
    });

    $elements.AMOUNT_WRAPPER.on('change click', function () {
      const $this = $(this);
      const $amount = $this.find('[data-connect="simAmount"]');
      STORE.dispatch({
        type: 'UPDATE_AMOUNT',
        data: parseInt($amount.val()),
      });
    });

    const render = (newState) => {
      const {simConfig, variations} = newState;
      let selectedCall = null;
      let selectedAlternativeNumber = null;
      let selectedData = null;
      let selectedVoice = null;

      const $totalTableRowDataPlan = $('.js-totalTable_row_dataPlan');
      const $totalTableRowAirtime = $('.js-totalTable_row_airtime');
      const $totalTableRowExtraNumber = $('.js-totalTable_row_extraNumber');

      if (simConfig && simConfig.callCredit) {
        selectedCall = variations[simConfig.callCredit];
      }

      if (simConfig && simConfig.alternativeNumber) {
        selectedAlternativeNumber = variations[simConfig.alternativeNumber];
      }

      if (simConfig && simConfig.dataPlan) {
        selectedData = variations[simConfig.dataPlan];
      }

      if (simConfig && simConfig.voicePlan) {
        selectedVoice = variations[simConfig.voicePlan];
      }

      /**
       * Airtime
       */
      if (selectedCall && selectedCall.id !== 'none') {
        $elements.SELECTED_CALL_PRICE.text(convertToReadablePrice(selectedCall.price));
        $elements.SELECTED_CALL_PRICE_ROW.show();

        // Add to total row
        const $airtimeText = $('*[data-variation-id="' + selectedCall.id + '"]').children().find('.selector_title').text().trim()
        $totalTableRowAirtime.show();
        $totalTableRowAirtime.find('.totalTable_rowTitle').text('Credit: ' + $airtimeText);
        $totalTableRowAirtime.find('.totalTable_rowValue').text(convertToReadablePrice(selectedCall.price));
      } else {
        $elements.SELECTED_CALL_PRICE_ROW.hide();
        $totalTableRowAirtime.hide();
      }

      /**
       * Alternative number
       */
      if (selectedAlternativeNumber && selectedAlternativeNumber.id !== 'none') {
        $elements.SELECTED_ALTERNATIVE_NUMBER_PRICE.text(convertToReadablePrice(selectedAlternativeNumber.price));
        $elements.SELECTED_ALTERNATIVE_NUMBER_PRICE_ROW.show();

        // Add to total row
        const $alternativeNumberText = $('*[data-bundle-item-id="' + selectedAlternativeNumber.bundled_item_id + '"]').children().find('.selector_title-small').text().trim()
        $totalTableRowExtraNumber.show();
        $totalTableRowExtraNumber.find('.totalTable_rowTitle').text('Extra Number: ' + $alternativeNumberText);
        $totalTableRowExtraNumber.find('.totalTable_rowValue').text(convertToReadablePrice(selectedAlternativeNumber.price));

      } else {
        $elements.SELECTED_ALTERNATIVE_NUMBER_PRICE_ROW.hide();
        $totalTableRowExtraNumber.hide();
      }

      /**
       * Data plan
       */
      if (selectedData && selectedData.id !== 'none') {
        $elements.SELECTED_DATA_PRICE.text(convertToReadablePrice(selectedData.price));
        $elements.SELECTED_DATA_PRICE_ROW.show();

        // Add to total view
        //const $daTaDataPlanText = $('.is-active[data-connect="dataBundleTabNav"]').find('.verticalTabs_navText').text().trim() // todo doesn't work.
        $totalTableRowDataPlan.show();
        $totalTableRowDataPlan.find('.totalTable_rowTitle').text('Data Plan: ' + selectedData.name);
        $totalTableRowDataPlan.find('.totalTable_rowValue').text(convertToReadablePrice(selectedData.price));

      } else {
        $elements.SELECTED_DATA_PRICE_ROW.hide();
        $totalTableRowDataPlan.hide();
      }

      if (selectedVoice && selectedVoice.id !== 'none') {
        $elements.SELECTED_VOICE_PRICE.text(convertToReadablePrice(selectedVoice.price));
        $elements.SELECTED_VOICE_PRICE_ROW.show();
      } else {
        $elements.SELECTED_VOICE_PRICE_ROW.hide();
      }

      $elements.CALL_CREDIT
        .removeClass(ClassName.ACTIVE)
        .filter('[data-variation-id="' + simConfig.callCredit + '"]')
        .addClass(ClassName.ACTIVE);

      if (simConfig.alternativeNumber && simConfig.alternativeNumber !== 'none') {
        $('button:not([data-bundle-item-id="' + variations[simConfig.alternativeNumber].bundled_item_id + '"]) select')
          .prop('selectedIndex', '0')

        $elements.ALTERNATIVE_NUMBER
          .removeClass(ClassName.ACTIVE)
          .filter('[data-bundle-item-id="' + variations[simConfig.alternativeNumber].bundled_item_id + '"]')
          .addClass(ClassName.ACTIVE);
      } else {
        $elements.ALTERNATIVE_NUMBER.removeClass(ClassName.ACTIVE)
      }

      $elements.DATA_PLAN
        .removeClass(ClassName.ACTIVE)
        .filter('[data-variation-id="' + simConfig.dataPlan + '"]')
        .addClass(ClassName.ACTIVE);

      $elements.DATA_PLAN_SELECTOR
        .removeClass(ClassName.ACTIVE)
        .filter('[data-variation-id="' + simConfig.pendingDataPlan + '"]')
        .addClass(ClassName.ACTIVE);

      if (simConfig.voicePlan) {
        $elements.VOICE_PLAN
          .removeClass(ClassName.ACTIVE)
          .filter('[data-variation-id="' + simConfig.voicePlan + '"]')
          .addClass(ClassName.ACTIVE);
      }

      if (simConfig.pendingVoicePlan) {
        $elements.VOICE_PLAN_SELECTOR
          .removeClass(ClassName.ACTIVE)
          .filter('[data-variation-id="' + simConfig.pendingVoicePlan + '"]')
          .addClass(ClassName.ACTIVE);
      }

      if (simConfig.pendingDataPlan !== 'none') {
        $elements.PENDING_NAV_TAB
          .removeClass(ClassName.ACTIVE)
          .filter('[data-bundle-item-id="' + variations[simConfig.pendingDataPlan].bundled_item_id + '"]')
          .addClass(ClassName.ACTIVE);
      } else {
        $elements.PENDING_NAV_TAB
          .removeClass(ClassName.ACTIVE)
          .filter('[data-bundle-item-id="' + simConfig.pendingDataBundle + '"]')
          .addClass(ClassName.ACTIVE);
      }

      /*
      if (simConfig.pendingVoicePlan !== 'none') {
        $elements.PENDING_NAV_TAB
          .removeClass(ClassName.ACTIVE)
          .filter('[data-bundle-item-id="' + variations[simConfig.pendingVoicePlan].bundled_item_id + '"]')
          .addClass(ClassName.ACTIVE);
      } else {
        $elements.PENDING_NAV_TAB
          .removeClass(ClassName.ACTIVE)
          .filter('[data-bundle-item-id="' + simConfig.pendingVoiceBundle + '"]')
          .addClass(ClassName.ACTIVE);
      }
      */

      $elements.DATA_NAV_TAB
        .removeClass(ClassName.ACTIVE)
        .filter('[data-bundle-item-id="' + simConfig.dataBundle + '"]')
        .addClass(ClassName.ACTIVE);

      $elements.DATA_TAB_CONTENT
        .removeClass(ClassName.ACTIVE)
        .filter('[data-bundle-item-id="' + simConfig.dataBundle + '"]')
        .addClass(ClassName.ACTIVE);

      if (simConfig.voiceBundle) {
        $elements.VOICE_NAV_TAB
          .removeClass(ClassName.ACTIVE)
          .filter('[data-bundle-item-id="' + simConfig.voiceBundle + '"]')
          .addClass(ClassName.ACTIVE);

        $elements.VOICE_TAB_CONTENT
          .removeClass(ClassName.ACTIVE)
          .filter('[data-bundle-item-id="' + simConfig.voiceBundle + '"]')
          .addClass(ClassName.ACTIVE);
      }

      if (simConfig.pendingDataPlan !== 'none') {
        $elements.PENDING_TAB_CONTENT
          .removeClass(ClassName.ACTIVE)
          .filter('[data-bundle-item-id="' + variations[simConfig.pendingDataPlan].bundled_item_id + '"]')
          .addClass(ClassName.ACTIVE);
      } else {
        $elements.PENDING_TAB_CONTENT
          .removeClass(ClassName.ACTIVE)
          .filter('[data-bundle-item-id="' + simConfig.pendingDataBundle + '"]')
          .addClass(ClassName.ACTIVE);
      }

      /*
      if (simConfig.pendingVoicePlan !== 'none') {
        $elements.PENDING_TAB_CONTENT
          .removeClass(ClassName.ACTIVE)
          .filter('[data-bundle-item-id="' + variations[simConfig.pendingVoicePlan].bundled_item_id + '"]')
          .addClass(ClassName.ACTIVE);
      } else {
        $elements.PENDING_TAB_CONTENT
          .removeClass(ClassName.ACTIVE)
          .filter('[data-bundle-item-id="' + simConfig.pendingVoiceBundle + '"]')
          .addClass(ClassName.ACTIVE);
      }
      */

      $(context).find(Selector.AMOUNT_SELECTOR).val(simConfig.amount);
    };

    const renderPrice = newState => {
      $(context).find(Selector.TOTAL).text(convertToReadablePrice(calcTotal(newState)));
    };

    const renderPlanInfo = newState => {
      if (newState.variations[newState.simConfig.dataPlan] && newState.variations[newState.simConfig.dataPlan].info) {
        $elements.DATA_PLAN_INFO
          .html(newState.variations[newState.simConfig.dataPlan].info);
      } else {
        $elements.DATA_PLAN_INFO.html('');
      }

      if (newState.variations[newState.simConfig.voicePlan] && newState.variations[newState.simConfig.voicePlan].info) {
        $elements.VOICE_PLAN_INFO
          .html(newState.variations[newState.simConfig.voicePlan].info);
      } else {
        $elements.VOICE_PLAN_INFO.html('');
      }
    };

    const createAddToCartForm = state => {
      let form = document.createElement("form");
      form.style.display = "none";

      const createFormField = (name, value) => {
        let field = document.createElement("input");
        field.value = value;
        field.name = name;
        return field;
      };

      const shouldAdd = data => {
        if (data && data !== 'none') {
          return true;
        } else {
          return false;
        }
      };


      if (shouldAdd(state.simConfig.amount)) {
        form.appendChild(createFormField('quantity', state.simConfig.amount));
      }


      if (shouldAdd(state.simConfig.travelsim_number)) {
        form.appendChild(createFormField('travelsim_number', state.simConfig.travelsim_number));
      }

      if (shouldAdd(state.add_to_cart_form_data)) {
        state.add_to_cart_form_data.forEach(item => {
          if (shouldAdd(item.key)) {
            form.appendChild(createFormField(item.key, item.value));
          }
        });
      }

      if (shouldAdd(state.simConfig.callCredit)) {
        state.variations[state.simConfig.callCredit].form_data.forEach(item => {
          if (shouldAdd(item.key)) {
            form.appendChild(createFormField(item.key, item.value));
          }
        });
      }

      if (shouldAdd(state.simConfig.alternativeNumber)) {
        state.variations[state.simConfig.alternativeNumber].form_data.forEach(item => {
          if (shouldAdd(item.key)) {
            form.appendChild(createFormField(item.key, item.value));
          }
        });
      }

      if (shouldAdd(state.simConfig.dataPlan)) {
        state.variations[state.simConfig.dataPlan].form_data.forEach(item => {
          if (shouldAdd(item.key)) {
            form.appendChild(createFormField(item.key, item.value));
          }
        });
      }

      if (shouldAdd(state.simConfig.voicePlan)) {
        state.variations[state.simConfig.voicePlan].form_data.forEach(item => {
          if (shouldAdd(item.key)) {
            form.appendChild(createFormField(item.key, item.value));
          }
        });
      }

      return form;
    };

    const validateSimConfig = state => {
      let isValid = true;
      const {simConfig} = state;
      if (simConfig.dataPlan === 'none' && simConfig.callCredit === 'none' && simConfig.voicePlan === 'none') {
        isValid = false;
      }
      return isValid;
    };

    const shouldValidateSimConfig = state => {
      return state.validateSimConfig;
    };

    /**
     * Alternative number
     */
    const validateAltNumberConfig = state => {
      let isValid = true;
      const {simConfig} = state;
      if (simConfig.alternativeNumber === 'none' || !simConfig.alternativeNumber) {
        isValid = false;
      }
      return isValid;
    };

    const shouldValidateAltNumberConfig = state => {
      return state.validateAltNumberConfig;
    };

    let $errorMessage = null;

    const addToCart = state => {
      if ($errorMessage) {
        $errorMessage.remove();
      }

      if (shouldValidateSimConfig(state)) {
        if (!validateSimConfig(state)) {
          $errorMessage = $('<div class="alert alert-spaced alert-error">' + GLOBAL_CONFIG.strings.noSelectionConfigError + '</div>');
          $errorMessage.insertBefore($elements.ADD_TO_CART);
          return false;
        }
      }

      /**
       * Validate extra number
       */
      if (shouldValidateAltNumberConfig(state)) {
        if (!validateAltNumberConfig(state)) {
          $errorMessage = $('<div class="alert alert-spaced alert-error">' + GLOBAL_CONFIG.strings.noSelectionAltNumberConfigError + '</div>');
          $errorMessage.insertBefore($elements.ADD_TO_CART);
          return false;
        }
      }

      removeState(GLOBAL_CONFIG.pageId);
      const form = createAddToCartForm(state);
      document.body.appendChild(form);

      // Add Form data to 'Add to Basket' button in order to make add_to_cart event work for the Google Tag Manager

      const inputElements = form.querySelectorAll('input');
      const formData = {};
      inputElements.forEach((input) => {
        const name = input.getAttribute('name');
        const value = input.value;
        formData[name] = value;
      });

      const productDiv = document.body.querySelector('.product');
      // If such a div is found
      if (productDiv && formData) {
        // Find the link with class "add_to_cart" inside the div
        const addToCartLink = productDiv.querySelector('a.add_to_cart');
        // If such a link exists
        if (addToCartLink) {
          // Find the span with class "gtm4wp_productdata" inside the div
          const gtmSpan = productDiv.querySelector('span.gtm4wp_productdata');

          // If such a span exists
          if (gtmSpan) {
            // Remove all attributes with names starting with "data-gtm4wp_"
            const existingDataAttrs = gtmSpan.attributes;
            for (let i = existingDataAttrs.length - 1; i >= 0; i--) {
              const attr = existingDataAttrs[i];
              if (attr.name.startsWith('data-gtm4wp_')) {
                gtmSpan.removeAttribute(attr.name);
              }
            }

            // Add parameters to the span under the button
            let nameValue = '';
            const formDataKeys = Object.keys(formData);
            for (let i = 0; i < formDataKeys.length; i++) {
              const name = formDataKeys[i];
              const value = formData[name];
              let dataAttrValue = '';

              if (name === 'add-to-cart') {
                gtmSpan.setAttribute('data-gtm4wp_product_id', value);
              } else if (name === 'quantity') {
                gtmSpan.setAttribute('data-gtm4wp_product_quantity', value);
              } else {
                nameValue = `${nameValue}-${name}_(${value})`;
              }
              if (i === formDataKeys.length - 1) {
                gtmSpan.setAttribute(`data-gtm4wp_product_name`, nameValue);
              }
            }

            const panelTotalDiv = form.querySelector('.panel_total');
            if (panelTotalDiv) {
              const priceSpan = panelTotalDiv.querySelector('span[data-connect="total"]');
              if (priceSpan) {
                const price = priceSpan.textContent;
                const digitsOnly = price.replace(/\D/g, '');
                gtmSpan.setAttribute(`data-gtm4wp_product_price`, digitsOnly);
              }
            }
          }
        }
      }

// end
      form.submit();
    };

    $elements.ADD_TO_CART.on('click', function (e) {
      e.preventDefault();
      addToCart(STORE.getState());
    });

    connectToState(STORE, state => state)(render);
    connectToState(STORE, state => state)(renderPlanInfo);
    connectToState(STORE, state => state)(renderPrice);

    // amount plus/minus control
    //
    const $numberInput = $('.js-numberInput');

    function incrementValue(e) {
      e.preventDefault();
      const fieldName = $(e.target).data('field');
      const parent = $(e.target).closest('div');
      const currentVal = parseInt(parent.find('input[name=' + fieldName + ']').val(), 10);

      if (!isNaN(currentVal) && currentVal < 10) {
        parent.find('input[name=' + fieldName + ']').val(currentVal + 1);
      } else {
        parent.find('input[name=' + fieldName + ']').val(10);
      }
    }

    function decrementValue(e) {
      e.preventDefault();
      const fieldName = $(e.target).data('field');
      const parent = $(e.target).closest('div');
      const currentVal = parseInt(parent.find('input[name=' + fieldName + ']').val(), 10);

      if (!isNaN(currentVal) && currentVal > 1) {
        parent.find('input[name=' + fieldName + ']').val(currentVal - 1);
      } else {
        parent.find('input[name=' + fieldName + ']').val(1);
      }
    }

    $numberInput.on('click', '.numberInput_plus', function (e) {
      incrementValue(e);
    });

    $numberInput.on('click', '.numberInput_minus', function (e) {
      decrementValue(e);
    });

  },
};

