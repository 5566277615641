import forOwn from 'lodash.forown';

const GLOBAL_CONFIG = window.jsconfig || {};
const LIST_OF_COUNTRIES = window.listOfCountries || [];
const LIST_OF_DESTINATIONS = window.listOfDestinations || [];
import keySort from '../util/keySort';

export default {
  init(args) {
    const {context, config, STORE} = args;

    const State = {
      countries: [],
    };

    const Event = {
      RENDER: 'zf.render',
    };

    const ClassName = {
      REMOVE_ITEM: 'js-removeCountry',
    };

    const Selector = {
      ZONE_FINDER_SEARCH: '.js-esimFinderSearch',
      ZONE_FINDER_SEARCH_INPUT: '.js-esimFinderSearch_input',
      ZONE_FINDER_SEARCH_RESULTS: '.js-esimFinderSearch .autocomplete-suggestion',
      TABLE: '.tt-planBuilder_results',
      REMOVE_ITEM: '.' + ClassName.REMOVE_ITEM,
      ZONE_SUGGESTION: '.js-zoneSuggestion',
      ZONE_SELECT_LINK: '.js-selectZoneLink',
      PLAN_BUILDER: '.tt-planBuilder_content',
      DATA_PACKAGES: '#dataPackages',
      DATA_PACKAGES_NOTICE: '#dataPackageNotice',
      DATA_CREDITS: '#dataCredits',
      DATA_CREDITS_NOTICE: '#dataCreditNotice',
      POPULAR_CHOICES: '.tt-planBuilder_choice',
      SUBMIT_BUTTON: '*[data-connect="addToCart"]',
    };

    const $elements = {
      ZONE_FINDER_SEARCH: $(document).find(Selector.ZONE_FINDER_SEARCH),
      ZONE_FINDER_SEARCH_INPUT: $(context).find(Selector.ZONE_FINDER_SEARCH_INPUT),
      ZONE_FINDER_SEARCH_RESULTS: $(context).find(Selector.ZONE_FINDER_SEARCH_RESULTS),
      TABLE: $(document).find(Selector.TABLE),
      ZONE_SUGGESTION: $(context).find(Selector.ZONE_SUGGESTION),
      PLAN_BUILDER: $(document).find(Selector.PLAN_BUILDER),
      DATA_PACKAGES: $(document).find(Selector.DATA_PACKAGES),
      DATA_PACKAGES_NOTICE: $(document).find(Selector.DATA_PACKAGES_NOTICE),
      DATA_CREDITS: $(document).find(Selector.DATA_CREDITS),
      DATA_CREDITS_NOTICE: $(document).find(Selector.DATA_CREDITS_NOTICE),
      POPULAR_CHOICES: $(document).find(Selector.POPULAR_CHOICES),
      SUBMIT_BUTTON: $(document).find(Selector.SUBMIT_BUTTON),
    };

    const CurrentProductType = $(context).data('js-product-type');

    const addCountryToState = country => {
      if (!State.countries.find(elem => elem.id === country.id)) {
        State.countries.push(country);
        $(context).trigger(Event.RENDER);
      }
    };

    const removeCountryFromState = id => {
      State.countries = State.countries.filter(country => {
        return country.id !== id;
      });
      $(context).trigger(Event.RENDER);
    };

    const commonZones = countries => {
      let matchingZones = [];

      const doAllCountriesShareZone = zone => {
        let answer = true;

        if (countries.find(country => !(country.zones).includes(zone))) {
          answer = false;
        }
        return answer;
      };

      // the order of base zones determines the first suggestion
      const baseZones = ['zone-a', 'zone-b', 'zone-c'];
      const isBaseZone = zone => {
        return baseZones.indexOf(zone) > -1;
      };

      // find first common base zone
      const baseZone = baseZones.find(zone => {
        return doAllCountriesShareZone(zone);
      });

      if (baseZone) {
        matchingZones.push(baseZone);
      }

      // find other matching zones
      forOwn(GLOBAL_CONFIG.zones[CurrentProductType], zone => {
        if (!isBaseZone(zone.slug)) {
          if (doAllCountriesShareZone(zone.slug)) {
            matchingZones.push(zone.slug);
          }
        }
      });

      return matchingZones;
    };

    const suggestionTextController = () => {
      const zones = commonZones(State.countries);
      const defaultZone = 'esim-zone-a';
      let selectedZone = defaultZone;
      let availableZones = [];
      if (State.countries.length > 0) {
        if (zones.length) {
          let zoneLinks = zones.map(zone => {
            if (!getZoneInfo(zone)) {
              return '';
            }
            availableZones.push(getZoneInfo(zone).slug);
            return '<a href="#" class="js-selectZoneLink" data-zone-id="' + zone + '">' + getZoneInfo(zone).displayName + '</a>';
          });
          availableZones = availableZones.filter(item => item !== 'asia');
          availableZones = availableZones.filter(item => item !== 'esim-europe-extra');
          showDataPackages(availableZones[0])
          selectedZone = availableZones[0];
          if (availableZones.length > 0) {
            $elements.DATA_PACKAGES_NOTICE.hide();
            $elements.DATA_CREDITS_NOTICE.hide();
            $elements.DATA_PACKAGES.show();
            $elements.DATA_CREDITS.show();
            $elements.SUBMIT_BUTTON.removeClass('disabled');
          } else {
            $elements.DATA_PACKAGES.find('*[data-variation-id="none"]').first().click();
          }
        }
      } else {
        showDataPackages(defaultZone);
        $elements.DATA_PACKAGES_NOTICE.hide();
        $elements.DATA_CREDITS_NOTICE.hide();
        $elements.DATA_PACKAGES.show();
        $elements.DATA_CREDITS.show();
        $elements.SUBMIT_BUTTON.removeClass('disabled');
      }

      $('.list-of-countries a').hide();
      var selectedLink = $('.list-of-countries a[data-selecter="' + selectedZone + '"]');
      if (selectedLink.length > 0) {
        selectedLink.show();
      }
    };

    const showDataPackages = (zone) => {
      const dataPackages = $elements.DATA_PACKAGES.find('button');
      dataPackages.each(function () {
        const $this = $(this);
        $this.hide();
        if ($this.data('zone') === zone) {
          $this.show();
        }
      });
    }
    const tableRowTemplate = (content) => {
      return '<div class="tt-planBuilder_result">' +
        content +
        '<button class="tt-planBuilder_resultClose ' + ClassName.REMOVE_ITEM + '">' +
        '<div class="u-scalingSvg u-scalingSvg-tt-x">' +
        '<svg class="u-scalingSvg_shape">' +
        '<use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#shape-tt-x"></use>' +
        '</svg>' +
        '</div>' +
        '</button>' +
        '</div>';
    };

    const getZoneInfo = id => GLOBAL_CONFIG.zones[CurrentProductType][id];

    const rowRenderer = (country) => {
      let content1 = country.value;
      let content2 = GLOBAL_CONFIG.strings.countryNotSupported || '';

      if (country.zones.length) {
        let zoneNames = [];
        country.zones.forEach(zone => {

          if (!getZoneInfo(zone)) {
            return;
          }

          zoneNames.push(getZoneInfo(zone).shortName);
        });
        content2 = zoneNames.join(', ');
      }

      $elements.TABLE.append($(tableRowTemplate(content1, content2)).attr('data-id', country.id));
    };

    const tableRenderController = (rowRenderer, state) => {
      if (state.countries.length > 0) {
        $elements.TABLE.html('');
        state.countries.forEach((country) => {
          rowRenderer(country);
          $elements.TABLE.show();
        });
      } else {
        $elements.TABLE.hide();
      }
    };

    const showNotice = (country) => {
      $elements.SUBMIT_BUTTON.addClass('disabled');
      $elements.DATA_PACKAGES.find('*[data-variation-id="none"]').first().click();
      $elements.DATA_CREDITS.find('*[data-variation-id="none"]').first().click();
      $elements.DATA_PACKAGES_NOTICE.find('.notice_country').html(country.value);
      $elements.DATA_CREDITS_NOTICE.find('.notice_country').html(country.value);
      $elements.DATA_PACKAGES_NOTICE.show();
      $elements.DATA_CREDITS_NOTICE.show();
      $elements.DATA_PACKAGES.hide();
      $elements.DATA_CREDITS.hide();
    };

    $(context).on(Event.RENDER, function () {
      suggestionTextController();
      tableRenderController(rowRenderer, State);
    });

    $elements.TABLE.on('click', Selector.REMOVE_ITEM, function (e) {
      removeCountryFromState($(e.currentTarget).closest('.tt-planBuilder_result').data('id'));
    });

    $elements.POPULAR_CHOICES.on('click', function (e) {
      let choice = $(e.currentTarget).text();
      let finder = $(document).find(Selector.ZONE_FINDER_SEARCH_INPUT);
      let selected = $('.tt-planBuilder_results').text();
      if (!selected.includes(choice)) {
        $(finder).val(choice);
        $(finder).trigger("change");
        //todo: select from $elements
        $('.tt-planBuilder_unitContent .autocomplete-suggestion').first().trigger('click');
      }
      e.preventDefault();
    });

    $elements.ZONE_FINDER_SEARCH.each(function () {
      const container = this;
      const input = $(container).find(Selector.ZONE_FINDER_SEARCH_INPUT);
      const countries = window.listOfCountries;
      const destinations = window.listOfDestinations;
      const globalConfig = window.countrySearchConfig || {};
      const options = {
        lookup: countries.sort(keySort('value')),
        lookupFilter: function (suggestion, originalQuery, queryLowerCase) {
          return suggestion.value.toLowerCase().indexOf(queryLowerCase) === 0;
        },
        appendTo: $(container),
        showNoSuggestionNotice: true,
        noSuggestionNotice: 'No results',
        maxHeight: 200,
        triggerSelectOnValidInput: false,
        autoSelectFirst: true,
        onSelect: function (country) {
          if(country.redirect) {
            let index = destinations.findIndex(x => x.code === country.code);
            window.location.href = destinations[index].url;
          }
          if (!country.zones.length) {
            showNotice(country);
          }
          addCountryToState(country);
          $(input).val('');
        },
      };

      const config = $.extend({}, options, globalConfig);

      if (!countries) {
        return;
      }

      $(input).autocomplete(config);
    });

    $(context).on('click', Selector.ZONE_SELECT_LINK, function (e) {
      e.preventDefault();
      const $this = $(e.target);
      const zone = $this.data('zone-id');
      let bundleItem = null;
      forOwn(STORE.getState().bundledItems, item => {
        if (item.zone === zone) {
          bundleItem = item;
        }
      });

      STORE.dispatch({
        type: 'UPDATE_PENDING_DATA_PLAN',
        data: bundleItem.default_variation,
      });

      STORE.dispatch({
        type: 'UPDATE_PENDING_DATA_BUNDLE',
        data: bundleItem.id,
      });
    });

    const loadInitialState = config => {
      /* preSelectedCountries contains only country id's
      * but we have to get the full country data from the LIST_OF_COUNTRIES
      * to populate the countries state
      */
      const {preSelectedCountries} = config;
      let countries = [];

      LIST_OF_COUNTRIES.forEach(country => {
        preSelectedCountries.forEach(id => {
          if (country.id === id) {
            countries.push(country);
          }
        });
      });

      countries.forEach(country => {
        addCountryToState(country);
      });
    };

    loadInitialState(config);

  },
};
