import modalCreator from '../util/modalCreator';

const globalConfig = window.jsconfig;

export default () => {
    const modalId = 'emptyCartNotification';

    const $modal = modalCreator({
        id: modalId,
        title: globalConfig.strings.cartIsEmptyNotification,
    });

    $('.js-cartLink').on('click', function (e) {
        if ($(this).data('item-count') === 0) {
            e.preventDefault();
            if (!$('#' + modalId).length) {
                $("body").prepend($modal);
            }
            $modal.modal('show');
        }
    });

    const mbUpdateFixedCart = () => {
        var ajaxRequest = $.ajax({ // eslint-disable-line no-unused-vars
            method: 'GET',
            url: window.ajaxurl,
            data: {
                action: 'travelsim_get_cart_count',
            },
        }).done(function (response) {

            var $cartCountContainer = $('.js-cartCount');

            if ($cartCountContainer.data('item-count') !== response){
              $cartCountContainer.data('item-count',response);
              $cartCountContainer.text(response);
            }
        });
    };

    mbUpdateFixedCart();
};
