const GLOBAL_CONFIG = window.jsconfig || {};
const LIST_OF_COUNTRIES = window.listOfCountries || [];
import keySort from '../util/keySort';

export default {
  init(args) {
    const { context } = args;

    //$('#rateFinderModal').modal('show');

    const Event = {
      RENDER: 'rf.render',
      FETCH: 'rf.fetch',
      PARAM_CHANGE: 'rf.paramChange',
    };

    const Selector = {
      SEARCH_FROM: '.js-rateFinderSearchFrom',
      SEARCH_FROM_INPUT: '.js-rateFinderSearchFrom_input',
      SEARCH_TO: '.js-rateFinderSearchTo',
      SEARCH_TO_INPUT: '.js-rateFinderSearchTo_input',
      TABLE_CONTAINER: '.js-ratesTable',
      TABLE_CONTAINER_ESIM: '.js-ratesTable-esim',
      TABLE_CONTAINER_ESIM_ASIA: '.js-ratesTable-esimAsia',
      TABLE_CONTAINER_TRAVELSIM: '.js-ratesTable-travelsim',
    };

    const $elements = {
      SEARCH_FROM: $(context).find(Selector.SEARCH_FROM),
      SEARCH_FROM_INPUT: $(context).find(Selector.SEARCH_FROM_INPUT),
      SEARCH_TO: $(context).find(Selector.SEARCH_TO),
      SEARCH_TO_INPUT: $(context).find(Selector.SEARCH_TO_INPUT),
      TABLE_CONTAINER: $(context).find(Selector.TABLE_CONTAINER),
      TABLE_CONTAINER_ESIM: $(context).find(Selector.TABLE_CONTAINER_ESIM),
      TABLE_CONTAINER_ESIM_ASIA: $(context).find(Selector.TABLE_CONTAINER_ESIM_ASIA),
      TABLE_CONTAINER_TRAVELSIM: $(context).find(Selector.TABLE_CONTAINER_TRAVELSIM),
    };

    let TABLE_CONTENT = '';

    let PARAMS = {
      from: null,
      to: null,
      currency: GLOBAL_CONFIG.currency,
    };

    const setParam = (key, value) => {
      PARAMS[key] = value;
      $(context).trigger(Event.PARAM_CHANGE);
    };

    // helpers
    const getCountryName = code => LIST_OF_COUNTRIES.find(country => country.code === code).value;

    const translate = (string, translations) => {
      let translatedString = string;
      translations.forEach(translation => {
        translatedString = translatedString.replace('[[' + translation.key + ']]', translation.translation);
      });
      return translatedString;
    };

    const convertPrice = price => {
      return price / 100;
    };

    const currencyString = price => {
      return GLOBAL_CONFIG.currency.symbol + price;
    };

    const formatPrice = (price, unit) => {
      const strings = GLOBAL_CONFIG.strings.rateFinder;
      let unitString = '';

      if (price === null) {
        return strings.notAvailable;
      }

      if (unit === 'MB') {
        unitString = strings.perMB;
      } else if (unit === 'minute') {
        unitString = strings.perMinute;
      }

      return currencyString(convertPrice(price)) + ' ' + unitString;
    };


    const fetchData = (params) => {
      const { from, to, currency } = params;
      let query = '';

      // make sure all required params are present
      if (!from || !to || !currency) {
        return;
      }


      query = GLOBAL_CONFIG.apiEndPoints.fromToRates + '?';
      query = query +
        'from_country_code=' + from +
        '&to_country_code=' + to;


      query = query + '&currency_code=' + currency.code;

      $.getJSON(query, data => {
          TABLE_CONTENT = data;
          $(context).trigger(Event.RENDER);
        })
        .fail((jqXHR, textStatus, errorThrown) => { // eslint-disable-line no-unused-vars
          console.log('fail');
        })
        .always((response) => { // eslint-disable-line no-unused-vars

        });
    };



    const tableRowTemplate = rowContent => {
      const $tr = $('<tr></tr>');
      rowContent.forEach(data => {
        $tr.append('<td>' + data + '</td>');
      });

      return $tr;
    };

    const tableTemplate = data => {
      const $table = $('<table class="table table-striped table-rates"></table>');
      const strings = GLOBAL_CONFIG.strings.rateFinder;

      $table.append(tableRowTemplate(
        [
          translate(strings.incomingCall, [{ key: 'country_from', translation: getCountryName(data['from-country-code']) }]),
          formatPrice(data.rates.call_in, 'minute'),
        ]
      ));

      if (data['to-country-code']) {
        $table.append(tableRowTemplate(
          [
            translate(strings.outgoingCall, [
              { key: 'country_from', translation: getCountryName(data['from-country-code']) },
              { key: 'country_to', translation: getCountryName(data['to-country-code']) },
            ]),
            formatPrice(data.rates.call_out, 'minute'),
          ]
        ));
      }

      $table.append(tableRowTemplate(
        [
          translate(strings.text, [
            { key: 'country_from', translation: getCountryName(data['from-country-code']) },
            { key: 'country_to', translation: getCountryName(data['to-country-code']) },
          ]),
          formatPrice(data.rates.sms),
        ]
      ));

      $table.append(tableRowTemplate(
        [
          translate(strings.data, [
            { key: 'country_from', translation: getCountryName(data['from-country-code']) },
          ]),
          formatPrice(data.rates.data, 'MB'),
        ]
      ));

      if (data['from-country-direct-call']) {
        $table.append(tableRowTemplate(
          [
            translate(strings.supports, [
              { key: 'country_from', translation: getCountryName(data['from-country-code']) },
            ]),
            data['from-country-direct-call'],
          ]
        ));
      }

      return $table;
    };

    const renderController = () => {
      // clear previous content
      $elements.TABLE_CONTAINER.html('');

      if (TABLE_CONTENT.hasOwnProperty('travelsim')) {
        $elements.TABLE_CONTAINER_TRAVELSIM.append(tableTemplate(TABLE_CONTENT.travelsim));
      }

      if (TABLE_CONTENT.hasOwnProperty('esim')) {
        $elements.TABLE_CONTAINER_ESIM.append(tableTemplate(TABLE_CONTENT.esim));
      }

      if (TABLE_CONTENT.hasOwnProperty('esimAsia')) {
        $elements.TABLE_CONTAINER_ESIM_ASIA.append(tableTemplate(TABLE_CONTENT.esimAsia));
      }
    };


    $elements.SEARCH_FROM.each(function () {
      const container = this;
      const input = $(container).find(Selector.SEARCH_FROM_INPUT);
      const globalConfig = window.countrySearchConfig || {};
      const options = {
        lookup: LIST_OF_COUNTRIES.sort(keySort('value')),
        lookupFilter: function (suggestion, originalQuery, queryLowerCase) {
          return suggestion.value.toLowerCase().indexOf(queryLowerCase) === 0;
        },
        appendTo: $(container),
        showNoSuggestionNotice: true,
        noSuggestionNotice: 'No results',
        maxHeight: 200,
        triggerSelectOnValidInput: false,
        autoSelectFirst: true,
        onSelect: function (country) {
          setParam('from', country.code);
        },
      };

      const config = $.extend({}, options, globalConfig);

      $(input).autocomplete(config);
    });

    $elements.SEARCH_TO.each(function () {
      const container = this;
      const input = $(container).find(Selector.SEARCH_TO_INPUT);
      const globalConfig = window.countrySearchConfig || {};
      const options = {
        lookup: LIST_OF_COUNTRIES.sort(keySort('value')),
        lookupFilter: function (suggestion, originalQuery, queryLowerCase) {
          return suggestion.value.toLowerCase().indexOf(queryLowerCase) === 0;
        },
        appendTo: $(container),
        showNoSuggestionNotice: true,
        noSuggestionNotice: 'No results',
        maxHeight: 200,
        triggerSelectOnValidInput: false,
        autoSelectFirst: true,
        onSelect: function (country) {
          setParam('to', country.code);
        },
      };

      const config = $.extend({}, options, globalConfig);

      $(input).autocomplete(config);
    });


    // events

    $(context).on(Event.PARAM_CHANGE, function () {
      fetchData(PARAMS);
    });

    $(context).on(Event.RENDER, function () {
      renderController();
    });

    const setInitalFromCountry = () => {
      let defaultCountry = $(context).data('default-country');
      if (!defaultCountry) {
        return;
      }

      const countryName = getCountryName(defaultCountry);
      $elements.SEARCH_FROM.each(function () {
        $(this).find(Selector.SEARCH_FROM_INPUT).val(countryName);
      });
      setParam('from', defaultCountry);
    };
    setInitalFromCountry();

  },
};
