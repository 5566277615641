/** import external dependencies */
import 'jquery';
import Popper from 'popper.js/dist/umd/popper';

window.Popper = Popper;
import 'nodelist-foreach-polyfill';
import 'bootstrap';
import 'slick-carousel';
import 'jquery-match-height';


/** import local  plugins */
import './plugins/jquery.scrollTo.min.js';
import './plugins/overMenu';
import './plugins/overPanel';
import './plugins/jquery.autocomplete.js';
import './plugins/sticky-kit.js';
import './plugins/jquery-jvectormap-modified.min.js';

/** import local dependencies */
import Router from './util/Router';
import common from './components/common';
import testimonials from './components/testimonials';
import simconfig from './components/simconfig';
import zonefinder from './components/zonefinder';
import ratefinder from './components/ratefinder';
import faq from './components/faq';
import configModal from './sim-config/index';
import ratesMap from './rates-map/index';
import esimfinder from './2023-design/tt_esimFinder'
import detectStylesheetLoaded from './util/detectStylesheetLoaded';


import configureStore from './store/store';
import { loadState, saveState } from "./util/localStorage";

detectStylesheetLoaded(document.getElementById('stylesheetLoadedTest'), 'color', 'rgb(255, 0, 0)');

const globalConfig = window.jsconfig;


let initialState = window.initialState || {};

if (globalConfig && globalConfig.pageId) {
  let persistedState = loadState(globalConfig.pageId);
  if (persistedState) {
    initialState = Object.assign({}, initialState, persistedState);
  }
}

const STORE = configureStore(initialState);


$('body').on('submit', '.js-saveSimConfigOnSubmit', function () {
  saveState(globalConfig.pageId, {
    simConfig: STORE.getState().simConfig,
  });
});


$('.js-simConfigRoot').each(function () {
  const thisProductConfig = window.topconnectProductConfig[$(this).data('product-config-id')];
  configModal.init($(this).attr('id'), thisProductConfig);
});



// fetch maps svg data and init ratesmaps
const $mapRoots = $('.js-ratesMapRoot');

function initRatesMaps($mapRoots) {
  $mapRoots.each(function () {
    ratesMap.init($(this).attr('id'), {
      countries: window.listOfCountries || [],
      apiEndPoints: globalConfig.apiEndPoints,
      currency: globalConfig.currency,
      zoneVariations: window.ratesMapData.zoneVariations,
      countryDefaultZones: window.ratesMapData.countryDefaultZones,
    });
  });
}

if ($mapRoots.length) {
  const maps = window.ratesMapData.maps;
  $.when(...maps.map(map => $.getJSON(map.url)))
    .done((...args) => {
      args.forEach(arg => {
        jQuery.fn.vectorMap('addMap', arg[0].name, arg[0].map_data);
      });
      initRatesMaps($mapRoots)
    })
    .fail((jqXHR, textStatus) => {
      console.log('Couldn\'t fetch the maps: ' + textStatus);
    });
}

/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */
const routes = new Router({
  // Global
  common,
  testimonials,
  simconfig,
  zonefinder,
  ratefinder,
  faq,
  esimfinder,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents(STORE));
