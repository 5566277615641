export default () => {
  const $slider = $('.js-tt-setupSlider');
  const $dots = $slider.parent().find('.js-tt-setupSlider_dots');

  $slider.slick({
    rows: 1,
    slidesToShow: 1,
    infinite: true,
    arrows: false,
    dots: true,
    fade: true,
    draggable: true,
    focusOnSelect: false,
    appendDots: $dots,
    autoplay: false,
    autoplaySpeed: 6000,
    speed: 500,
  });

  $slider.on('setPosition', () => {
    $slider.find('.js-tt-setupSlider_slide').matchHeight();
  });
};
