import _debounce from 'lodash.debounce';

export default () => {
  const Selector = {
    LIST: '.js-countryList',
    ITEM: '.js-countryList_item',
  };

  const MediaQueries = {
    SM_UP: "(min-width: 576px)",
    MD_UP: "(min-width: 768px)",
  };

  function columns(list) {

    const $items = $(list).find(Selector.ITEM);


    function arrangeIntoColumns(nrOfCols = 3) {
      const colSettings = {
        nrOfCols: nrOfCols,
      };

      if (window.matchMedia(MediaQueries.MD_UP).matches) {
        colSettings.nrOfCols = 6;
      } else if (window.matchMedia(MediaQueries.SM_UP).matches) {
        colSettings.nrOfCols = 4;
      }

      colSettings.quotient = Math.floor($items.length / colSettings.nrOfCols)
      colSettings.remainder = Math.floor($items.length % colSettings.nrOfCols)


      function colLength(currentColNr, colSettings) {
        const { nrOfCols, remainder, quotient } = colSettings;
        if ((nrOfCols - currentColNr) >= (nrOfCols - remainder)) {
          return quotient + 1;
        } else {
          return quotient;
        }
      }

      function distributeIntoColumns($items, numberOfCols) {
        let columnsArray = [];

        // convert jquery collection into a plain array
        const domNodes = [];
        $items.each((index, element) => {
          domNodes.push(element);
        });

        for (let i = 1; i <= numberOfCols; i++) {
          columnsArray.push(domNodes.splice(0, colLength(i, colSettings)));
        }
        return columnsArray;
      }

      function render() {
        $(list).html('');

        const columnsArray = distributeIntoColumns($items, colSettings.nrOfCols);
        columnsArray[0].forEach((item, index) => {
          for (let i = 0; i < columnsArray.length; i++) {
            // Print each iteration to the console
            if (columnsArray[i][index]) {
              $(columnsArray[i][index]).appendTo($(list));
            }
          }
        });
      }

      render();

    }

    function refresh() {
      arrangeIntoColumns();
    }

    arrangeIntoColumns();

    return {
      refresh,
    };
  }

  // create columns instances
  const countryListColumns = [];
  $(Selector.LIST).each((index, list) => {
    countryListColumns.push(columns(list));
  });

  const debouncedRefresh = _debounce(function () {
    countryListColumns.forEach(element => {
      element.refresh();
    });
  }, 500);

  $(window).on('resize', debouncedRefresh);
};