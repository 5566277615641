/**
 * DOM-based Routing
 *
 * Based on {@link http://goo.gl/EUTi53|Markup-based Unobtrusive Comprehensive DOM-ready Execution} by Paul Irish
 *
 * The routing fires all common scripts, followed by the component specific scripts.
 * Add additional events for more control over timing e.g. a finalize event
 */
class Router {

  /**
   * Create a new Router
   * @param {Object} routes
   */
  constructor(routes) {
    this.routes = routes;
  }

  /**
   * Fire Router events
   * @param {string} route DOM-based route derived data attributes (`data-js-route="...">`)
   * @param {string} [event] Events on the route. By default, `init` and `finalize` events are called.
   * @param {string} [arg] Any custom argument to be passed to the event.
   */
  fire(route, event = 'init', arg) {
    const fire = route !== '' && this.routes[route] && typeof this.routes[route][event] === 'function';
    if (fire) {
      this.routes[route][event](arg);
    }
  }

  /**
   * Automatically load and fire Router events
   *
   * Events are fired in the following order:
   *  * common init
   *  * component-specific init
   *  * component-specific finalize
   *  * common finalize
   */
  loadEvents(STORE) {
    let defaultArgs = {
      context: null,
      config: null,
      STORE: STORE,
    };
    // Fire common init JS
    this.fire('common', 'init', defaultArgs);

    // Fire component-specific init JS, and then finalize JS
    document.querySelectorAll('[data-js-component]').forEach((elem) => {
      let routeName = elem.dataset.jsComponent;
      let config = null;
      if (elem.dataset.jsConfig) {
        config = JSON.parse(elem.dataset.jsConfig);
      }
      let arg = Object.assign({}, defaultArgs, {
        context: elem,
        config: config,
      });
      this.fire(routeName, 'init', arg);
      this.fire(routeName, 'finalize', arg);
    });


    // Fire common finalize JS
    this.fire('common', 'finalize', defaultArgs);
  }
}

export default Router;