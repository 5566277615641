export default () => {
    const Selector = {
        INFO_TABLE: '.js-call-info-table',
        SETTINGS: '.js-call-info-settings',
        BUTTON: '.js-find-call-data-button',
        START_DATE: 'input[name="start_date"]',
        END_DATE: 'input[name="end_date"]',
        TRAVELSIM_NUMBER: 'input[name="travelsim_number"]',
    };

    const $elements = {
        INFO_TABLE: $(Selector.INFO_TABLE),
        SETTINGS: $(Selector.SETTINGS),
        BUTTON: $(Selector.BUTTON),
        START_DATE: $(Selector.START_DATE),
        END_DATE: $(Selector.END_DATE),
        TRAVELSIM_NUMBER: $(Selector.TRAVELSIM_NUMBER),
    };

    $elements.BUTTON.on('click', function (e) {
        e.preventDefault();
        updateCallLogTable();
    });

    const updateCallLogTable = () => {
        var blockArgs = {
            message: '',
            overlayCSS: {
                background: '#fff',
                opacity: 0.6,
            },
        };

        $elements.SETTINGS.block(blockArgs);
        $elements.INFO_TABLE.block(blockArgs);

        var ajaxRequest = $.ajax({
            method: 'GET',
            url: window.ajaxurl,
            data: {
                action: 'getSimCallDataAdminAction',
                start_date: $elements.START_DATE.val(),
                end_date: $elements.END_DATE.val(),
                travelsim_number: $elements.TRAVELSIM_NUMBER.val(),
            },
        });

        ajaxRequest.done(function (response) {
            $elements.INFO_TABLE.html(response);
        });

        ajaxRequest.fail(function (response) {
            console.log(response);
        });

        ajaxRequest.always(function () {
            $elements.SETTINGS.unblock();
            $elements.INFO_TABLE.unblock();
        })
    };
    //updateCallLogTable();
};
