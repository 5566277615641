export default () => {
  const elem = '.js-scrollSticky';
  const elem2 = '.js-overMenu';
  const stickyClass = 'is-sticky';

  function shouldBeSticky(scrollElem) {
    return $(scrollElem).scrollTop() > 0;
  }

  if (shouldBeSticky(window)) {
    $(elem).addClass(stickyClass);
    $(elem2).addClass(stickyClass);
  }

  $(window).on('scroll', function() {
    if (shouldBeSticky(this)) {
      $(elem).addClass(stickyClass);
      $(elem2).addClass(stickyClass);
    } else {
      $(elem).removeClass(stickyClass);
      $(elem2).removeClass(stickyClass);
    }
  });
};
